import React from "react";
import "./BlogDetails.css";
import Img1 from "./../../../assets/hostelimg/blogimg1.jpeg";
import { Link } from "react-router-dom";
import { AiOutlineRight } from 'react-icons/ai';
import parse from "html-react-parser";

const BlogDetails = (props) => {
  const {blogDetailsTitle,blogDetailsDiscription,blogDetailsImage}=props;
  return (
    <>
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="blog-details-main-box-discription">
              <img src={blogDetailsImage} className="mb-30 img1-blog" />
              <h2>{blogDetailsTitle}</h2>
              <p>
              {blogDetailsDiscription && parse(blogDetailsDiscription)}
              </p>
            </div>
            </div>
            <div className="col-md-4">
              <div className="news2-sidebar row">
                <div className="col-md-12">
                  <div className="widget">
                    <div className="widget-title">
                      <h6>Recent Posts</h6>
                    </div>
                    <ul className="recent">
                      <li>
                        <div className="thum">
                          <img src={Img1} />
                        </div>
                        <Link to="">Historic restaurant renovated</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="widget">
                    <div className="widget-title">
                      <h6>Categories</h6>
                    </div>
                    <ul>
                      <li>
                        <Link to="">
                          <span className="ti-angle-right"></span>
                          Male
                        </Link>

                      </li>
                      <li>
                        <Link to="">
                          <span className="ti-angle-right"></span>
                          Female
                        </Link>

                      </li>
                      <li>
                        <Link to="">
                          <span className="ti-angle-right"></span>
                         Comman
                        </Link>

                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetails;
