import React, { useEffect, useState } from 'react'
 import './PrivacyPolicy.css'
import { Link } from 'react-router-dom';
import Footer from '../../Common/Footer/Footer';
import Logo from "./../../assets/images/logo.svg";
import { endpoints } from '../../Services/endpoints';
import axios from 'axios';
import parse from "html-react-parser";


const PrivacyPolicy = () => { 
const [privacyPolicy,setPrivacyPolicy]=useState("");
useEffect(()=>{

    const privacyPolicyUrl=endpoints.home.privacyPolicy;
    const headers = {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
      };

      axios
      .get(privacyPolicyUrl,{headers:headers})
      .then((res)=>{
        if(res.data.status==1){
            var privacyPolicyData=res.data.data[0]?.content;

            console.log(privacyPolicyData,"privacyPolicyData")
            setPrivacyPolicy(privacyPolicyData)

        }
        
      }).catch((err)=>{
        console.log(err, "privacy policy api not response");
      })
},[])

useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop(); 
   
  }, []);


  return (
    <>
 <div className="top-header">
        <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-light bg-gradient">
          <div className="container">
            <div className="privacy-policy-logo">
              <Link to="/">
                {" "}
                <img src={Logo} />
              </Link>
            </div>
          </div>
        </nav>

        <div class="main-privacy-policy">
          <div className="privacy-policy-heading">
            <h2 className="privacy-policy-heading-text">Privacy Policy</h2>
            <p className="privacy-policy-sub-text">
              <span>
                <Link to="/">Home</Link>
              </span>
              &nbsp;<span>/</span>&nbsp;<span>Privacy Policy</span>
            </p>
          </div>
         {privacyPolicy && 
          <div className="privacy-policy-text-box">
            <div className="container">
              {parse(privacyPolicy)}
            </div>
          </div>
          }
          <Footer/>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy;