import React from "react";
import "./CheckOut.css";
import { Link } from "react-router-dom";
import UserIMG from "./../assets/images/checkout-problem.png";
import CheckOutRightBox from "./CheckOutRightBox";

const CheckOut = () => {
  return (
    <>
      <div className="_hostel-page-desktop-container">
        <div className="row justify-content-between">
          <div className="col-md-8 col-12">
            <div className="container-fluid _hostel-page-sub-sections material-shadow-card-3">
              <div className="_block-header">
                <h3 className="_block-header-text">
                  Please Login to Continue Booking
                </h3>
              </div>

              <div className="checkout-page-login-form-container">
                <div>
                  <div className="_login-through-otp-container">
                    <div className="needs-validation">
                      <div className="form-row">
                        <div className="col-md-12 p-0">
                          <div className="form-group">
                            <label>Mobile Login</label>
                            <input
                              className="form-control checkout-input-box"
                              type="tel"
                              maxLength={10}
                              placeholder="Enter 10 digit Mobile Number Eg: 8650150468"
                            />
                          </div>
                        </div>
                        <button type="button" className="btn_full">
                          Log In Via OTP
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
            <Link to="https://api.whatsapp.com/send?phone=918650150468">
                <div className="material-shadow-card-3 checkout-problem-whatsapp-button">
                  <div>
                    <div className="checkout-problem-img-box">
                      <img className="checkout-problem-img" src={UserIMG} />
                    </div>
                  </div>
                  <p className="checkout-problem-text">
                  Unable to login or facing payment issues ? Click to connect on WhatsApp
                  </p>
                </div>
              </Link>
          </div>
          <div className="col-md-4 col-12">
            <CheckOutRightBox/>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckOut;
