import React from "react";
import "./PopularHostel.css";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { AiOutlineStar, AiOutlinePlus } from "react-icons/ai";
import { useState, useEffect } from "react";

import { TiTick } from "react-icons/ti";
import Cvraman from "./../../../src/assets/hostelimg/cvramn.png";
import Bed from "./../../../src/assets/images/singlebed.png";
import { Link, useNavigate } from "react-router-dom";
import { endpoints } from "../../Services/endpoints";
import axios from "axios";
import { Skeleton } from "@mui/material";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PopularHostelCard = (props) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { data, image, feature, rating } = props;
  const newFeatures = feature.slice(0, 5);
  const newImg = image.slice(0, 1);

  // const handleViewDetails = () => {
  //   navigate(`/hostel-detail/${data.hostel_id}`);
  // };

  const handleBookNow = () => {
    if (token) {
      navigate(`/hotsel-detail/${data?.hostel_id}`);
    } else {
      toast("Please Login ", { type: "warning" });
      navigate("/login-signup");
    }
  };
  return (
    <>
      <div className="container-cards second-row-card" key={data.id}>
        <div className="hostel-cards-col">
          {newImg.length > 0 ? (
            <div className="media-img">
              {data?.hostel_membership && (
                <div className="ribbon">
                  <span className="ribbon3">{data?.hostel_membership}</span>
                </div>
              )}
              {newImg.map((itm, idx) => (
                <img key={idx} src={itm} alt="" />
              ))}
            </div>
          ) : (
            <Skeleton variant="rectangular" width="100%" height={200} />
          )}

          <div className="hostel-details">
            <h3> {data?.hostel_name} </h3>
            <h6>
              <span className="home-lockation-txt">{data?.hostel_address}</span>

              {rating.map((itm, idx) => (
                <span key={idx} className="home-rating">
                  <span className="rating-point">{itm.average_rating}</span>
                  <span className="home-rating-star">
                    <AiOutlineStar />
                  </span>
                </span>
              ))}
            </h6>
          </div>
          <div className="hostel-facility-details">
            <ul className="facility-text">
              {newFeatures.map((item, index) => (
                <li key={index}>
                  {" "}
                  <span>
                    <TiTick />
                  </span>
                  {item}
                </li>
              ))}
              <li>
                <Link to="" onClick={handleBookNow}>
                  View More
                  <span className="btn-pluse">
                    <AiOutlinePlus />
                  </span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="hostel-bed-avility-details">
            <h6>
              <span className="bed-avl-txt">Rent From</span>
              <span className="home-card-rating">
                <button className="book-now-home-btn" onClick={handleBookNow}>
                  Book Now
                </button>
              </span>
            </h6>
            <ul>
              {data?.single_bed_rent && (
                <li>
                  <img src={Bed} alt="Single Bed" />
                  &nbsp;
                  <span className="hostel-bed-price">
                    Rs.{data?.single_bed_rent}/-
                  </span>
                </li>
              )}
              {data?.double_bed_rent && (
                <li>
                  <img src={Bed} alt="Double Bed" />
                  <img src={Bed} alt="Double Bed" />
                  &nbsp;
                  <span className="hostel-bed-price">
                    Rs.{data?.double_bed_rent}/-
                  </span>{" "}
                </li>
              )}
              {data?.triple_bed_rent && (
                <li>
                  <img src={Bed} alt="triple Bed" />
                  <img src={Bed} alt="triple Bed" />
                  <img src={Bed} alt="triple Bed" />
                  &nbsp;
                  <span className="hostel-bed-price">
                    Rs.{data?.triple_bed_rent}/-
                  </span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

const PopularHostel = () => {
  const [hostelData, setHostelData] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");
  const options = {
    loop: true,
    margin: 0,
    rewind: true,
    // autoplay: true,
    items: 1,
    nav: true,
    responsive: {
      0: {
        items: 1,
        nav: true,
      },
      300: {
        items: 1,
        nav: true,
        margin:0,
      },
      600: {
        items: 1.5,
        nav: true,
      },
      700: {
        items: 2,
        nav: false,
      },
      800: {
        items: 2.2,
        nav: false,
      },
      900: {
        items: 2.3,
        nav: false,
      },
      1000: {
        items: 2.7,
        nav: true,
        loop: false,
      },
      1200: {
        items: 4,
        nav: true,
        loop: false,
      },
      1360: {
        items: 3.5,
        nav: true,
        loop: false,
      },
      1440: {
        items: 4,
        nav: true,
        loop: false,
      },
      1450: {
        items: 4.1,
        nav: true,
        loop: false,
      },
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(endpoints.home.popularHostels, {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `Bearer ${token}`, // Add this line
          },
        });
        if (response.data.status === 1) {
          setHostelData(response.data.data);
          console.log(response.data.data, "All popular hostel data");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching popular hostels:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);

  return (
    <>
      <div className="container-fluid p-0 popular-cont">
        <div className="_category-page-all-hostels-section material-shadow-card-light">
          <div className="_block-header">
            <div className="category-page-list-heading">
              Popular Hostels / PG
            </div>
          </div>
          {loading ? (
            <Skeleton variant="rectangular" width="100%" height="300" />
          ) : (
           
            <OwlCarousel className="owl-carousel owl-theme" {...options}  loop
            margin={10}
            nav >
              {hostelData.map((item, index) => (
                <div className="item" key={index}>
                  <PopularHostelCard
                    data={item}
                    image={item.hostel_images}
                    feature={item.hostel_features}
                    rating={item.reviews}
                  />
                </div>
              ))}
            </OwlCarousel>

          )}
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default PopularHostel;
