import React, { useEffect, useState } from "react";
import "./HostelDetails2.css";
import "./HostelDetailsMobileView.css";
import { AiOutlineStar, AiOutlineCheckCircle } from "react-icons/ai";
import HostelDetailsCarousel from "../../HostelDetailsCarousel/HostelDetailsCarousel";
import parse from "html-react-parser";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { TiTick } from "react-icons/ti";
import RightSectionDetails from "../RightSectionDetails/RightSectionDetails";
import ReactPlayer from "react-player";
import EnquiryForm from "../RightSectionDetails/EnquiryForm";
import { Button } from "react-bootstrap";
import SeatAvaillability from "./SeatAvaillability";
import SatrRating from "../RightSectionDetails/SatrRating";
import RiviewHostelDetails from "../RightSectionDetails/RiviewHostelDetails";
const HostelDetails2 = (props) => {
  const [hostelMembership, setHostelMembership] = useState("");
  const [activeSection, setActiveSection] = useState("section1");
  const [rating, setRating] = useState(0);

  
  const [description, setDescription] = useState(
    "Write your description here."
  );
  const [showFullContent, setShowFullContent] = useState(false);
  //Star Rating  code start
  const handleStarClick = (newRating) => {
    setRating(newRating);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };


  //Star Rating code end
  const {
    hostelTitle,
    hostelLocation,
    hostelLocationCity,
    hostelDiscription,
    hostelPolicy,
    hostelFeature,
    hostelImage,
    hostelType,
    hostelVedio,
    hostelOwnerId,
    detailsData,
    hostelId,
    hostelReview,
    setHostelReview,
  } = props;

  // console.log(detailsData,"hostelId")
  // console.log(hostelFeature,"hostelFeature here....")
  const toggleSection = (section) => {
    setActiveSection(section);
  };

  // Hide Show Component start

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };
  const truncateContent = (content, lines) => {
    if (content) {
      return content.split("\n").slice(0, lines).join("\n");
    } else {
      return ""; // or handle it in a way that makes sense for your use case
    }
  };
  const truncatedContent = truncateContent(hostelDiscription, 10);

  // Hide Show Component end

  return (
    <>
      <div className="inner-package-section common-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="package-left-area">
                <div className="productLeft_heading">
                  <div className="row">
                    <div className="col-lg-9 col-md-6 col-sm-12">
                      <h3>{hostelTitle}</h3>
                      <h5>
                        <span>
                          {detailsData.hostel_address}, {hostelLocationCity}
                        </span>
                      </h5>
                      {detailsData.hostel_membership && (
                        <h6 className="hostel-details-membership">
                          {detailsData?.hostel_membership}
                        </h6>
                      )}
                    </div>
                    <div className="col-lg-3 col-sm-12">
                      <div className="hodtel-detail-page-review">
                        {detailsData?.average_ratings && (
                          <div className="hostel-details-review-tech">
                            {detailsData?.average_ratings}
                            <span className="hostel-details-review-tech-star">
                              <AiOutlineStar />
                            </span>
                          </div>
                        )}
                        {detailsData?.total_reviews && (
                          <div className="hostel-details-review-text">
                            {detailsData?.total_reviews} <span>Review</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="product_left_para gallery_slider common-card">
                  <HostelDetailsCarousel hostelImage={hostelImage} />
                </div>
                <div className="product_left_details common-card ">
                  <h4>Hostel Details </h4>
                  <div className="product_left_details_box">
                    <h6>
                      {/* {hostelDiscription && parse(hostelDiscription)} */}
                      {showFullContent
                        ? parse(hostelDiscription)
                        : parse(truncatedContent)}
                      {hostelDiscription &&
                        hostelDiscription.split("\n").length > 10 && (
                          <button
                            onClick={toggleContent}
                            className="read-more-read-less-btn"
                          >
                            + {showFullContent ? "Read Less" : "Read More"}
                          </button>
                        )}
                    </h6>
                  </div>
                </div>

                <div className="product_left_details common-card ">
                  <h4>Amenities</h4>
                  <div className="product_left_details_box">
                    <ul className="hostel-amenties-details-list">
                      {hostelFeature?.map((itm, idx) => {
                        return (
                          <>
                            <li key={idx}>{itm}</li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>

                <div className="product_left_details common-card ">
                  <h4>Nearby</h4>
                  <div className="product_left_details_box near-by-common">
                    <nav>
                      <ul className="nearme-details-nave">
                        <li
                          className={
                            activeSection === "section1" ? "active" : ""
                          }
                          onClick={() => toggleSection("section1")}
                        >
                          Restaurants
                        </li>
                        <li
                          className={
                            activeSection === "section2" ? "active" : ""
                          }
                          onClick={() => toggleSection("section2")}
                        >
                          Shoppoing Mall
                        </li>
                        <li
                          className={
                            activeSection === "section3" ? "active" : ""
                          }
                          onClick={() => toggleSection("section3")}
                        >
                          Transport
                        </li>
                      </ul>
                    </nav>
                    <div className="near-by-section-1-content-box">
                      {activeSection === "section1" && (
                        <div className="near-by-section-1-content Restaurants">
                          <li className="near-by-section-1-content-li">
                            <p>
                              <span className="near-by-section-star">
                                <AiOutlineStar />
                              </span>
                              Section 1 Content
                            </p>
                            <p>300 mtr</p>
                          </li>
                          <li className="near-by-section-1-content-li">
                            <p>
                              <span className="near-by-section-star">
                                <AiOutlineStar />
                              </span>
                              Section 1 Content
                            </p>
                            <p>300 mtr</p>
                          </li>
                          <li className="near-by-section-1-content-li">
                            <p>
                              <span className="near-by-section-star">
                                <AiOutlineStar />
                              </span>
                              Section 1 Content
                            </p>
                            <p>300 mtr</p>
                          </li>
                        </div>
                      )}

                      {activeSection === "section2" && (
                        <div className="near-by-section-1-content">
                          <li className="near-by-section-1-content-li">
                            <p>
                              <span className="near-by-section-star">
                                <AiOutlineStar />
                              </span>
                              Section 2 Content
                            </p>
                            <p>300 mtr</p>
                          </li>
                        </div>
                      )}

                      {activeSection === "section3" && (
                        <div className="near-by-section-1-content">
                          <li className="near-by-section-1-content-li">
                            <p>
                              <span className="near-by-section-star">
                                <AiOutlineStar />
                              </span>
                              Section 3 Content
                            </p>
                            <p>300 mtr</p>
                          </li>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="product_left_details common-card ">
                  <h4>Frequently asked questions</h4>
                  <div className="product_left_details_box">
                    <div className="faq-block">
                      <h4>
                        Q.1. What is the various mode of payment accepted here?{" "}
                      </h4>
                      <p>
                        <span>A.</span> You can make payments Via Cash, NEFT,
                        RTGS, IMPS, Money Orders, G Pay.{" "}
                      </p>
                      <h4>
                        Q.1. What is the various mode of payment accepted here?{" "}
                      </h4>
                      <p>
                        <span>A.</span> You can make payments Via Cash, NEFT,
                        RTGS, IMPS, Money Orders, G Pay.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                {hostelPolicy && (
                  <div className="product_left_details common-card ">
                    <h4>Policy</h4>
                    <div className="product_left_details_box details-policy">
                      {/* <h6 className="policy-cancel-payment">
                    Prepayment / Cancellation
                  </h6> */}
                      <ul className="hostel-policy-details">
                        <li>
                          {/* <span className="policy-circle-icon"><AiOutlineCheckCircle/></span> */}
                          {detailsData.hostel_policy &&
                            parse(detailsData.hostel_policy)}
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                {hostelVedio && (
                  <div className="product_left_details common-card ">
                    <h4>Hostel Vedio</h4>
                    <div className="product_left_details_box">
                      <ReactPlayer
                        className="reactplayer"
                        url={hostelVedio}
                        playsInline
                        controls="true"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="package-right-area">
                
              
                <RightSectionDetails detailsData={detailsData} hostelId={hostelId}hostelOwnerId={hostelOwnerId} />
                <SatrRating hostelId={hostelId} hostelOwnerId={hostelOwnerId} hostelReview={hostelReview} setHostelReview={setHostelReview}/>
                {/* <RiviewHostelDetails hostelReview={hostelReview}/> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HostelDetails2;
