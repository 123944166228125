import React, { useEffect, useState } from "react";
import "./Locations.css";
import LocationDetail from "./LocationDetail";
import Right from "./../../assets/images/rightarrow.png";
import { endpoints } from "../../Services/endpoints";
import axios from "axios";
const Locations = () => {
  const [loading, setLoading] = useState(true);
  const [cityName, setCityName] = useState([]);
  const [subcityName, setSubCityName] = useState([]);
  
 

  useEffect(() => {
    const cityUrls = endpoints.home.city;

    const headers = {
      "Content-Type": "application/json; charset=utf-8",
    };
    setLoading(true);
    axios
      .get(cityUrls, { headers: headers })

      .then((res) => {
        if (res.data.status === 1) {
          setLoading(false);
          const val = res.data.data;
         
          setCityName(val);
          // const cityIdsFromApi = res.data.data.map((city) => city.city_id);
          // console.log(cityIdsFromApi, "cityIdsFromApi");
          // console.log(val, "city api  respond");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, "city api not respond");
      });
  }, []);



  
  // const getUnderLocation = (id) => {
  //   const locatioUrl = `https://admin.nearmehostel.com/api/location/${id}`;

  //   const headers = {
  //     "Content-Type": "application/json; charset=utf-8",
  //   };
  //   setLoading(true);
  //   axios
  //     .get(locatioUrl, { headers: headers })

  //     .then((res) => {
  //       if (res.data.status == "1") {
  //         setLoading(false);
  //         const val2 = res.data.data;
  //         // console.log(val2, "val2 data");
  //         setSubCityName(val2);
  //       }
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       console.log(err, "city api not respond");
  //     });
  // };

  return (
    <>
      <div className="locations">
        {cityName.map((item) => (
          
               <div key={item.city_id}>
                <LocationDetail
                  name={item.city}
                  cityId={item.city_id}
                  data={item}

                  // getUnderLocation={getUnderLocation}
                  // more={subcityName}
                />
              </div>
        
        ))}
      </div>
    </>
  );
};

export default Locations;
