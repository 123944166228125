import React, { useEffect, useState } from "react";
import "./RiviewHostelDetails.css";
import User from "./../../../../src/assets/images/avatar3.png";
import { FaStar } from "react-icons/fa";

const RiviewHostelDetails = ({ reviews }) => {
  const [showAll, setShowAll] = useState(false);

  const handleToggle = () => {
    setShowAll(!showAll);
  };

  return (
    <>
      {reviews && reviews.length > 0 && (
        <div className="riview-section-box-hostel">
          <div className="_block-header">
            <h4>Review</h4>
          </div>
          {reviews.slice(0, showAll ? reviews.length : 3).map((review, index) => (
            <div key={index} className="container-fluid my-3 p-0 _single-reviews-list-item">
              <div className="row no-gutters">
                <div className="col-auto">
                  <img src={review?.image || User} className="img-cover-no-stretch" alt="user" />
                </div>
                <div className="col">
                  <p className="_review-customer-name">{review.name}</p>
                  <p className="_review-customer-review-date">{review?.date}</p>
                  <h6 className="user-riview-star">
                    {/* {generateStars(review.star)} */}
                    {'★'.repeat(review.star)}
                  </h6>
                  <p className="user-riview-txt">{review.description}</p>
                </div>
              </div>
            </div>
          ))}
          {reviews.length > 3 && (
            <button className="custom-btn btn-1" onClick={handleToggle}>
              {showAll ? "Read Less" : "Read More"}
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default RiviewHostelDetails;
