import React, { useEffect, useRef, useState } from "react";
import "./UserProfileForm.css";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';

const UserProfileForm = () => {
  const token = localStorage.getItem("token");
  const fileInputRef = useRef(null);
  const [imageName, setImageName] = useState("");
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    phone: '',
    dob: '',
    gender: '',
    aadhar_no: '',
    alternate_no: '',
    address: '',
    current_address: '',
    country: '',
    blood_group: '',
    university: '',
    courseYear: '',
    father_name: '',
    mother_name: '',
    father_mobile_no: '',
    mother_mobile_no: '',
    guardian_name: '',
    guardian_mobile_no: '',
    aadhar_front: null,
    aadhar_back: null,
    image: null
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const storedUserData = localStorage.getItem("userdata");
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'aadhar_no' && value.length > 12) {
      return;
    }
   // Limit the length of mobile numbers to 10 digits
  const mobileNumberFields = ['phone', 'alternate_no', 'father_mobile_no', 'mother_mobile_no', 'guardian_mobile_no'];
  if (mobileNumberFields.includes(name) && value.length > 10) {
    return;
  }
    setUserData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: files[0]
    }));
    setImageName(files[0].name); // Update imageName if needed
  };

  const validateFields = () => {
    const requiredFields = [
      'name', 'email', 'phone', 'dob', 'gender', 'aadhar_no', 'address',
      'current_address', 'country',
      'father_name', 'mother_name', 'father_mobile_no', 'mother_mobile_no'
      
    ];
  
    let errors = {};
    for (let field of requiredFields) {
      if (!userData[field]) {
        errors[field] = `The ${field.replace(/([A-Z])/g, ' $1').toLowerCase()} field is required.`;
      }
    }
  
    const validGenders = ['Male', 'Female', 'Other'];
    if (!validGenders.includes(userData.gender)) {
      errors.gender = 'The selected gender is invalid.';
    }
  
    return Object.keys(errors).length > 0 ? errors : null;
  };

  const updateProfile = () => {
    const updateProfileUrl = "https://admin.nearmehostel.com/api/student/edit-profile";
    const formData = new FormData();
    // Append all userData to formData
    Object.keys(userData).forEach(key => {
      if (userData[key] !== null && !(userData[key] instanceof File)) {
        formData.append(key, userData[key]);
      }
    });

    // Append files separately
    if (userData.aadhar_front) {
      formData.append('aadhar_front', userData.aadhar_front);
    }
    if (userData.aadhar_back) {
      formData.append('aadhar_back', userData.aadhar_back);
    }
    if (userData.image) {
      formData.append('image', userData.image);
    }

    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    };
  
    axios({
      method: 'post',
      url: updateProfileUrl,
      data: formData,
      headers: headers,
    })
    .then(response => {
      toast.success('Profile updated successfully!');
      localStorage.setItem("userdata", JSON.stringify(userData));
     
    })
    .catch(error => {
      if (error.response && error.response.data) {
        setErrors(error.response.data.errors || {});
        // toast.error('Error updating profile. Please check the highlighted fields.');
      } else {
        toast.error('Error updating profile');
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateFields();
    if (validationErrors) {
      setErrors(validationErrors);
      Object.keys(validationErrors).forEach(error => {
        // toast.error(validationErrors[error]);
        console.error(validationErrors[error]);
      });
    } else {
      updateProfile();
    }
  };

  return (
    <>
      <Toaster />
      <div className="card">
        <section className="registration-container">
          <h2> Update Profile </h2>
          <section className="details-section">
            <form className="registration-form" onSubmit={handleSubmit}>
              <h4> Personal Details </h4>
              <section className="personal-details">
                <div className="three-details-item">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <label htmlFor="name" className="user-profile-labels">
                        <p>Full Name</p>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Enter your name"
                          className={`user-profile-input-boxes ${errors.name ? 'is-invalid' : ''}`}
                          value={userData.name}
                          onChange={handleInputChange}
                        />
                        {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                      </label>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <label htmlFor="email" className="user-profile-labels">
                        <p>Email</p>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Enter your email"
                          className={`user-profile-input-boxes ${errors.email ? 'is-invalid' : ''}`}
                          value={userData.email}
                          onChange={handleInputChange}
                        />
                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                      </label>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <label htmlFor="phone" className="user-profile-labels">
                        <p>Mobile Number</p>
                        <input
                          type="tel"
                          name="phone"
                          id="phone"
                          placeholder="Enter mobile number"
                          className={`user-profile-input-boxes ${errors.phone ? 'is-invalid' : ''}`}
                          value={userData.phone}
                          onChange={handleInputChange}
                        />
                        {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                      </label>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <label htmlFor="dob" className="user-profile-labels">
                        <p>Date of Birth</p>
                        <input
                          type="date"
                          name="dob"
                          id="dob"
                          placeholder="Enter your DOB"
                          className={`user-profile-input-boxes ${errors.dob ? 'is-invalid' : ''}`}
                          value={userData.dob}
                          onChange={handleInputChange}
                        />
                        {errors.dob && <div className="invalid-feedback">{errors.dob}</div>}
                      </label>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <label htmlFor="gender" className="user-profile-labels">
                        <p>Gender</p>
                        <input
                          type="text"
                          name="gender"
                          id="gender"
                          placeholder="Enter your gender"
                          className={`user-profile-input-boxes ${errors.gender ? 'is-invalid' : ''}`}
                          value={userData.gender}
                          onChange={handleInputChange}
                        />
                        {errors.gender && <div className="invalid-feedback">{errors.gender}</div>}
                      </label>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <label htmlFor="aadhar_no" className="user-profile-labels">
                        <p>Aadhar Number</p>
                        <input
                          type="text"
                          name="aadhar_no"
                          id="aadhar_no"
                          placeholder="Enter aadhar number"
                          className={`user-profile-input-boxes ${errors.aadhar_no ? 'is-invalid' : ''}`}
                          value={userData.aadhar_no}
                          onChange={handleInputChange}
                        />
                        {errors.aadhar_no && <div className="invalid-feedback">{errors.aadhar_no}</div>}
                      </label>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <label htmlFor="alternate_no" className="user-profile-labels">
                        <p>Alternative Mobile</p>
                        <input
                          type="tel"
                          name="alternate_no"
                          id="alternate_no"
                          placeholder="Enter mobile number"
                          className={`user-profile-input-boxes ${errors.alternate_no ? 'is-invalid' : ''}`}
                          value={userData.alternate_no}
                          onChange={handleInputChange}
                        />
                        {errors.alternate_no && <div className="invalid-feedback">{errors.alternate_no}</div>}
                      </label>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <label htmlFor="current_address" className="user-profile-labels">
                        <p>Current Address</p>
                        <input
                          type="text"
                          name="current_address"
                          id="current_address"
                          placeholder="Enter your current address"
                          className={`user-profile-input-boxes ${errors.current_address ? 'is-invalid' : ''}`}
                          value={userData.current_address}
                          onChange={handleInputChange}
                        />
                        {errors.current_address && <div className="invalid-feedback">{errors.current_address}</div>}
                      </label>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <label htmlFor="address" className="user-profile-labels">
                        <p>Permanent Address</p>
                        <input
                          type="text"
                          name="address"
                          id="address"
                          placeholder="Enter your permanent address"
                          className={`user-profile-input-boxes ${errors.address ? 'is-invalid' : ''}`}
                          value={userData.address}
                          onChange={handleInputChange}
                        />
                        {errors.address && <div className="invalid-feedback">{errors.address}</div>}
                      </label>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <label htmlFor="country" className="user-profile-labels">
                        <p>Country</p>
                        <input
                          type="text"
                          name="country"
                          id="country"
                          placeholder="Enter your country"
                          className={`user-profile-input-boxes ${errors.country ? 'is-invalid' : ''}`}
                          value={userData.country}
                          onChange={handleInputChange}
                        />
                        {errors.country && <div className="invalid-feedback">{errors.country}</div>}
                      </label>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <label htmlFor="blood_group" className="user-profile-labels">
                        <p>Blood Group</p>
                        <input
                          type="text"
                          name="blood_group"
                          id="blood_group"
                          placeholder="Enter your blood group"
                          className={`user-profile-input-boxes ${errors.blood_group ? 'is-invalid' : ''}`}
                          value={userData.blood_group}
                          onChange={handleInputChange}
                        />
                        {errors.blood_group && <div className="invalid-feedback">{errors.blood_group}</div>}
                      </label>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <label htmlFor="image" className="user-profile-labels">
                        <p>Profile Image</p>
                        <input
                          type="file"
                          name="image"
                          id="image"
                          accept=".jpg,.jpeg,.png"
                          className="user-profile-input-boxes"
                          onChange={handleFileChange}
                         
                        />
                        {errors.image && <div className="invalid-feedback">{errors.image}</div>}
                      </label>
                    </div>
                  </div>
                </div>
              </section>
              <h4>Parent/Guardian Details</h4>
              <section className="parent-details">
                <div className="three-details-item">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <label htmlFor="father_name" className="user-profile-labels">
                        <p>Father's Name</p>
                        <input
                          type="text"
                          name="father_name"
                          id="father_name"
                          placeholder="Enter father's name"
                          className={`user-profile-input-boxes ${errors.father_name ? 'is-invalid' : ''}`}
                          value={userData.father_name}
                          onChange={handleInputChange}
                        />
                        {errors.father_name && <div className="invalid-feedback">{errors.father_name}</div>}
                      </label>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <label htmlFor="father_mobile_no" className="user-profile-labels">
                        <p>Father's Mobile Number</p>
                        <input
                          type="tel"
                          name="father_mobile_no"
                          id="father_mobile_no"
                          placeholder="Enter father's mobile number"
                          className={`user-profile-input-boxes ${errors.father_mobile_no ? 'is-invalid' : ''}`}
                          value={userData.father_mobile_no}
                          onChange={handleInputChange}
                        />
                        {errors.father_mobile_no && <div className="invalid-feedback">{errors.father_mobile_no}</div>}
                      </label>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <label htmlFor="mother_name" className="user-profile-labels">
                        <p>Mother's Name</p>
                        <input
                          type="text"
                          name="mother_name"
                          id="mother_name"
                          placeholder="Enter mother's name"
                          className={`user-profile-input-boxes ${errors.mother_name ? 'is-invalid' : ''}`}
                          value={userData.mother_name}
                          onChange={handleInputChange}
                        />
                        {errors.mother_name && <div className="invalid-feedback">{errors.mother_name}</div>}
                      </label>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <label htmlFor="mother_mobile_no" className="user-profile-labels">
                        <p>Mother's Mobile Number</p>
                        <input
                          type="tel"
                          name="mother_mobile_no"
                          id="mother_mobile_no"
                          placeholder="Enter mother's mobile number"
                          className={`user-profile-input-boxes ${errors.mother_mobile_no ? 'is-invalid' : ''}`}
                          value={userData.mother_mobile_no}
                          onChange={handleInputChange}
                        />
                        {errors.mother_mobile_no && <div className="invalid-feedback">{errors.mother_mobile_no}</div>}
                      </label>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <label htmlFor="guardian_name" className="user-profile-labels">
                        <p>Guardian's Name</p>
                        <input
                          type="text"
                          name="guardian_name"
                          id="guardian_name"
                          placeholder="Enter guardian's name"
                          className={`user-profile-input-boxes ${errors.guardian_name ? 'is-invalid' : ''}`}
                          value={userData.guardian_name}
                          onChange={handleInputChange}
                        />
                        {errors.guardian_name && <div className="invalid-feedback">{errors.guardian_name}</div>}
                      </label>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <label htmlFor="guardian_mobile_no" className="user-profile-labels">
                        <p>Guardian's Mobile Number</p>
                        <input
                          type="tel"
                          name="guardian_mobile_no"
                          id="guardian_mobile_no"
                          placeholder="Enter guardian's mobile number"
                          className={`user-profile-input-boxes ${errors.guardian_mobile_no ? 'is-invalid' : ''}`}
                          value={userData.guardian_mobile_no}
                          onChange={handleInputChange}
                        />
                        {errors.guardian_mobile_no && <div className="invalid-feedback">{errors.guardian_mobile_no}</div>}
                      </label>
                    </div>
                  </div>
                </div>
              </section>
              <h4>Identification Details</h4>
              <section className="identification-details">
                <div className="three-details-item">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <label htmlFor="aadhar_front" className="user-profile-labels">
                        <p>Aadhar Front</p>
                        <input
                          type="file"
                          name="aadhar_front"
                          id="aadhar_front"
                          accept=".jpg,.jpeg,.png"
                          className={`user-profile-input-boxes ${errors.aadhar_front ? 'is-invalid' : ''}`}
                       
                          onChange={handleFileChange}
                        />
                        {errors.aadhar_front && <div className="invalid-feedback">{errors.aadhar_front}</div>}
                      </label>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <label htmlFor="aadhar_back" className="user-profile-labels">
                        <p>Aadhar Back</p>
                        <input
                          type="file"
                          name="aadhar_back"
                          id="aadhar_back"
                          accept=".jpg,.jpeg,.png"
                          className={`user-profile-input-boxes ${errors.aadhar_back ? 'is-invalid' : ''}`}
                        
                          onChange={handleFileChange}
                        />
                        {errors.aadhar_back && <div className="invalid-feedback">{errors.aadhar_back}</div>}
                      </label>
                    </div>
                   
                  </div>
                </div>
              </section>
              <div className="btn-submit">
                <button type="submit" className="update-profile-button">Update</button>
              </div>
            </form>
          </section>
        </section>
      </div>
    </>
  );
};

export default UserProfileForm;
