import React from "react";
import "./CheckOutRightBox.css";
import { MdOutlineDateRange, MdOutlineAccessTime } from "react-icons/md";
import { BiSolidEdit } from "react-icons/bi";
import IMG1 from "./../assets/hostelimg/blogimg1.jpeg";

const CheckOutRightBox = () => {
  return (
    <>
      <div className="checkout-sticky-sidebar">
        <div>
          <div className="container-fluid _hostel-page-sub-sections material-shadow-card-3">
            <div className="_block-header">
              <h3 className="_block-header-text">Order Summary</h3>
            </div>
            <p className="order-summury-box-text">
              Please complete Login for Payment, Coupon and Edit booking options
            </p>
            <div className="checkout-order-summary-disable">
              <div className="booking-date-time disable">
                <div className="info-section">
                  <span>
                    <MdOutlineDateRange />
                  </span>
                  <span className="content">14 Dec</span>
                </div>
                <div className="info-section">
                  <span>
                    <MdOutlineAccessTime />
                  </span>
                  <span className="content">4:00 PM to 7:00 PM</span>
                </div>
                <div className="info-section">
                  <span>
                    <BiSolidEdit />
                  </span>
                  <span className="content">Edit</span>
                </div>
              </div>
              <div className="experience-summary-container">
                <div className="section-box">
                  <div className="hostel-orders-details mb-3">
                    <img className="img-cover-no-stretch" src={IMG1} />
                    <span className="orders-name">
                      Candy Land Birthday Decoration
                    </span>
                    <span className="orders-cost">₹ 2399</span>
                  </div>
                  <div className="row non-important-text">
                    <div className="col-8 label-left">Booking Charges</div>
                    <div className="col-4 label-right">₹0</div>
                  </div>
                </div>
                <div className="_hostel-page-final-pricing">
                  <div className="label-left">Total</div>
                  <div className="label-right">₹ 8500</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckOutRightBox;
