import React, { useState } from "react";
import "./RefundPolicy.css";
import Logo from "./../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import Footer from "../../Common/Footer/Footer";
import { useEffect } from "react";
import { endpoints } from "../../Services/endpoints";
import axios from "axios";
import parse from "html-react-parser";

const RefundPolicy = () => {
  const [refundPolicy, setRefundPolicy] = useState("");
  useEffect(() => {
    const refundPolicyUrl = endpoints.home.refundPolicy;
    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
    };
    axios
      .get(refundPolicyUrl, { headers: headers })
      .then((res) => {
        if (res.data.status == 1) {
          var refunPolicyData = res.data.data?.content;
          setRefundPolicy(refunPolicyData);
          console.log(refunPolicyData, "refunPolicyData");
        }
      })
      .catch((err) => {
        console.log(err, "refund policy api not response");
      });
  }, []);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop(); 
   
  }, []);
  return (
    <>
      <div className="top-header">
        <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-light bg-gradient">
          <div className="container">
            <div className="refund-policy-logo">
              <Link to="/">
                {" "}
                <img src={Logo} />
              </Link>
            </div>
          </div>
        </nav>

        <div class="main-refund-policy">
          <div className="refund-policy-heading">
            <h2 className="refund-policy-heading-text">Refund Policy</h2>
            <p className="refund-policy-sub-text">
              <span>
                <Link to="/">Home</Link>
              </span>
              &nbsp;<span>/</span>&nbsp;<span>Refund Policy</span>
            </p>
          </div>
         {refundPolicy && 
          <div className="refund-policy-text-box">
            <div className="container">
              {parse(refundPolicy)}
            </div>
          </div>
          }
          <Footer />
        </div>
      </div>
    </>
  );
};

export default RefundPolicy;
