import React from "react";
import "./Offer.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Skeleton } from "@mui/material";
import { Link } from "react-router-dom";

const Offer = (props) => {
  const { cityBanner, loading } = props;

  const options = {
    loop: true,
    margin: 10,
    rewind: true,
    autoplay: true,
    items: 1,
  };

  return (
    <>
      <div className="offers-sliders">
        {loading ? (
          <Skeleton variant="rectangular" width="100%" height="100%" />
        ) : (
          <OwlCarousel className="owl-theme" loop margin={10} nav {...options}>
            {cityBanner?.map((item, index) => {
              return (
                <>
                  <Link to={item.link} key={index}>
                    <div className="item offer-items">
                      <img
                        src={item.banner}
                        alt=""
                        className="offer-items-img"
                      />
                    </div>
                  </Link>
                </>
              );
            })}
          </OwlCarousel>
        )}
      </div>
    </>
  );
};

export default Offer;
