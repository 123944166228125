import React from "react";
import "./MessageCeo.css";
import CeoImg from "./CeoImages/whatwedo.jpeg";
import parse from "html-react-parser"

const MessageCeo = (props) => {
  const{allAboutData}=props;
   const whatWeDoData=allAboutData[0]?.message_content || " ";

  return (
    <>
      <div className="ceo-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6  col-sm-6 col-xs-6">
              <div className="ceo-content ">
                <h2>
                  {/* <span>{allAboutData[0]?.to_do_title}</span> */}
                </h2>
                <p>
                {parse(whatWeDoData)}
                </p>

            
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-xs-6">
              <div className="ceo-image float-left hidden-sm ceo-img-sm">
                <img src= {allAboutData[0]?.message_image} alt />
              </div>
            </div>
          </div>
        </div>
      </div>


    </>

    
  );
};

export default MessageCeo;
