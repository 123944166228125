import React from "react";
import "./SeatAvaillability.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import Modal from "./Modal";
import { endpoints } from "../../../Services/endpoints";
import axios from "axios";
const SeatAvaillability = (props) => {
  const { hostelId,detailsData } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [buttonColor, setButtonColor] = useState("lightgrey");
  const [cursor, setCursor] = useState("no-drop");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

 const [bedData, setBedData] = useState([]);
const [checkInDate,setCheckInDate] = useState("")
const [checkOutDate,setCheckOutDate] = useState("")



  const token = localStorage.getItem("token");
  

  const closeModal = () => {
    setIsOpen(false);
    document.body.classList.remove("modal-open");
  };

  const handleStartDateChange = (event) => {
    let inputValue = event.target.value;
    setStartDate(inputValue);

    setButtonState(inputValue, endDate);
  };

  const handleEndDateChange = (event) => {
    let inputValue = event.target.value;
    setEndDate(inputValue);

    setButtonState(startDate, inputValue);
  };

  const setButtonState = (startDate, endDate) => {
    const enableButton = startDate.length !== 0 && endDate.length !== 0;
    setButtonColor(enableButton ? "#15124b" : "lightgrey");
    setCursor(enableButton ? "pointer" : "no-drop");
  };

  const checkBed = () => {
    const bedUrls = endpoints.home.bedAvailability;

    const data = {
      hostel_id: hostelId,
      check_in_date: startDate,
      check_out_date: endDate,
    };

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(bedUrls, data, { headers: headers })
      .then((res) => {
        if (res.data.status === 1) {
          const valFloar = res.data.available_beds;
          setCheckInDate(res.data.check_in_date)
          setCheckOutDate(res.data.check_out_date)
          setBedData(valFloar);
          console.log(valFloar, "all bed data");
          setIsOpen(true);
          document.body.classList.add("modal-open");
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
          document.body.classList.remove("modal-open");
        }
      })
      .catch((err) => {
        console.error("seat availibility API not responding", err);
        toast.error(" Bed not available");
        document.body.classList.remove("modal-open");
      });
  };
  const todayDate = new Date().toISOString().split("T")[0];


  return (
    <>
      <ul className="hostel-all-rooms-availability">
        <li>
          <label className="check-avilability-label">
            Please Enter Your Date{" "}
            <span className="validate-avalilibility-star-requied">*</span>
          </label>{" "}
          <br />
          <input
            type="date"
            placeholder="choose your date"
            className="check-availibility-input"
            onChange={handleStartDateChange}
            value={startDate}
            min={todayDate}
          />
          <label className="check-avilability-label">
            Please Enter End Date{" "}
            <span className="validate-avalilibility-star-requied">*</span>
          </label>{" "}
          <br />
          <input
            type="date"
            placeholder="choose your date"
            className="check-availibility-input"
            onChange={handleEndDateChange}
            value={endDate}
            min={todayDate}
          />
          <button
            type="submit"
            className="check-availibility-button"
            onClick={checkBed}
            style={{
              backgroundColor: buttonColor,
              cousor: cursor,
            }}
            disabled={startDate.length === 0 || endDate.length === 0}
          >
            Check Avilibility & Book Now
          </button>
          {/* <div className="hostels-all-rooms"></div> */}
        </li>
        <ToastContainer />
      </ul>
      <Modal
      
        closeModal={closeModal}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        detailsData={detailsData}
        bedData={bedData}
        hostelId={hostelId}
        checkInDate={checkInDate}
        checkOutDate={checkOutDate}
      />
    </>
  );
};

export default SeatAvaillability;
