import React, { useEffect, useState } from "react";
import "./BlogScreens.css";
import HomeHeader from "./../Common/HomeHeader/HomeHeader";
import { Link } from "react-router-dom";
import BlogsCards from "../Component/BlogInfo/BlogsCards/BlogsCards";
import { endpoints } from "../Services/endpoints";
import axios from "axios";
import Footer from "../Common/Footer/Footer";
const BlogScreens = () => {
  const [blogData, setBlogData] = useState([]);
  useEffect(() => {
    const blogUrls = endpoints.home.blogs;

    const headers = {
      "Content-Type": "application/json; charset=utf-8",
    };

    axios
      .get(blogUrls, { headers: headers })
      .then((res) => {
        if (res.data.status == 1) {
          var val = res.data.data;
          setBlogData(val);
          console.log(val, "blogs data");
        }
      })
      .catch((err) => {
        console.log(err, "Blogs api not response");
      });
}, []);

useEffect(() => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  scrollToTop();
}, []);
  return (
    <>
      <div className="blog-screens">
        <HomeHeader />
        <div className="blog-screen-heading">
          <h2 className="blog-screen-heading-text">Blogs</h2>
          <p className="blog-screen-sub-text">
            <span>
              <Link to="/">Home</Link>
            </span>
            &nbsp;<span>/</span>&nbsp;<span>Blogs</span>
          </p>
        </div>

        <BlogsCards blogData={blogData} />
      </div>
      <Footer/>
    </>
  );
};

export default BlogScreens;
