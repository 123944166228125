import React from "react";
import "./AboutUs.css";
import AboutImg from "./AboutImages/about.jpeg";
import parse from "html-react-parser";
const AboutUs = (props) => {
const {allAboutData}=props;
const aboutContent=allAboutData[0]?.about_content || " ";

  return (
    <>
      <div className="aboutus-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <div className="aboutus-image float-left hidden-sm about-right-img">
                <img src={allAboutData[0]?.about_image} alt />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12col-md-6 col-sm-6 col-xs-6">
              <div className="aboutus-content ">
                <h2>
                 
                    {/* <span>{allAboutData[0]?.about_title}</span> */}
                  
                 
                </h2>
                <p>
                {parse(aboutContent)}
                </p>
                
                <div className="counter ">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="single-counter text-center">
                      <h2 className="counter">
                        <span>15</span>
                      </h2>
                      <p>YEARS OF EXPERIENCED</p>
                    </div>
                    <div className="single-counter text-center">
                      <h2 className="counter">
                        <span>100</span>
                      </h2>
                      <p>WEBSITES COMPLETED</p>
                    </div>
                    <div className="single-counter text-center">
                      <h2 className="counter">
                        <span>5</span>
                      </h2>
                      <p>AWARDS WON</p>
                    </div>
                    <div className="single-counter text-center">
                      <h2 className="counter">
                        <span>10</span>
                      </h2>
                      <p>CLIENTS</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
