import React from "react";
import "./BookingRightSection.css";
import { AiFillStar } from "react-icons/ai";
import { LuCalendarDays } from "react-icons/lu";
import { MdVerticalShades } from "react-icons/md";
import BOOKIMG from "./../../../assets/images/logo.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const   BookingRightSection = ({
  detailsData,
  hostelId,
  checkInDate,
  checkOutDate,
  bedFloor,
  selectedSeat,
  ownerId,
  selectedBeds,
  totalPrice,
  selectedRooms,
}) => {

  const navigate = useNavigate();
// console.log(selectedRooms,"detailsData in booking section")

  const selectedBedsCount = selectedBeds.length;

  const hostelImg = detailsData?.hostel_images;
  const newImg = hostelImg.slice(0, 1);

  const bookingHostel=(response)=>{
    const token = localStorage.getItem("token");
    const BookingUrl = 'https://admin.nearmehostel.com/api/student/add-booking';
    const bookingData = {
      hostel_id: hostelId,
      check_in_date: checkInDate,
      check_out_date: checkOutDate,
      owner_id: ownerId,
      room_id: selectedRooms,
      bed_id: selectedBeds,
      
      transaction_id: response.razorpay_payment_id,
      plateform_fee: totalPrice,
    };
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
      Authorization: `Bearer ${token}`,
    };
axios
.post(BookingUrl,bookingData,{headers:headers})
.then((res)=>{
  if (res.data.status === 1) {
    toast.success("Your booking was successful!");
    setTimeout(() => {
      navigate("/"); 
    }, 2000);
  }
  else{
    toast.error("Booking failed: ");
  }
})
.catch((err)=>{
  console.error("Booking API failed", err);
      toast.error("Booking failed: An error occurred.");
})

  }

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const openRazorpay = async () => {
    const res = await loadRazorpayScript();

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }

    const options = {
      key: 'rzp_test_2xp5hbZcfnYnT5', // Enter the Key ID generated from the Razorpay Dashboard
      amount: totalPrice * 100, // Amount is in currency subunits. Default currency is INR. Hence, 100 refers to 100 paise
      currency: 'INR',
      name: 'NearMe Hostel',
      description: 'Test Transaction',
      image: BOOKIMG, // Add your logo image URL here
      order_id: '', // This is a sample Order ID. Pass the actual Order ID generated from the backend
      handler: function (response) {
        bookingHostel(response);
      },
      prefill: {
        name: 'NearMe Hostel',
        // email: 'gaurav.kumar@example.com',
        // contact: '9999999999',
        description: "Thanks for purchasing",
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      theme: {
        color: '#3399cc',
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <>
      <div className="c-1qpcdvy booking-right-section-container">
        <div>
          <div className="c-gg4vpm bkng-top-section">
            <div className="c-5hicrt">
              <div className="c-6a3y35">{detailsData?.hostel_name}</div>
              <div className="c-zpzivj"></div>
              <div className="bkng-hostel-rating">
                <div className="hostelRating__wrapper" tabIndex="-1">
                  <span className="is-fontBold hostelRating__rating hostelRating__rating--fair hostelRating__rating--clickable">
                    <span>{detailsData?.average_ratings}</span>
                    <span className="hostelRating__star">
                      <span className="c-1in37i9">
                        <AiFillStar />
                      </span>
                    </span>
                  </span>
                  <span className="hostelRating__ratingSummary hostelRating__rating--clickable"></span>
                  <span className="hostelRating__dot">.</span>
                  <span className="hostelRating__ratingSummary">Fair</span>
                </div>
              </div>
            </div>
            <div className="c-2clfy8 bking-img-right">
              <div className="c-e2r6u2">
                <img src={newImg} />
              </div>
            </div>
          </div>
          <div className="c-1six52r book-bkng-details">
            <div className="c-79elbk bkng-dtlls-box">
              <div className="c-1thcb3a c-9whsf3 c-gx0lhm c-g4aqpa">
                <div className="c-1pzykbe">
                  <span className="c-1a5t1qn">
                    <LuCalendarDays />
                  </span>
                  <div className="hostel-row hostel-row--no-spacing datePickerDesktop datePickerDesktop--hostel">
                    <div className="hostel-cell hostel-cell--12-col hostel-cell--8-col-tablet hostel-cell--4-col-phone d-text16 is-fontBold datePickerDesktop__checkInOut u-textEllipsis">
                      <span className="datePickerDesktop__checkInOutText">
                        {checkInDate}
                      </span>
                      <span className="datePickerDesktop__arrowWrapper datePickerDesktop__arrowWrapper--hostel">
                        -
                      </span>
                      <span className="datePickerDesktop__checkInOutText">
                        {checkOutDate}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="c-14mt3w6 c-107tt68">
                  <div className="hostel-row hostel-row--no-spacing guestRoomPicker guestRoomPicker--hostel">
                    <div className="hostel-cell hostel-cell--12-col hostel-cell--8-col-tablet hostel-cell--4-col-phone d-text16 is-fontBold u-textEllipsis guestRoomPicker__guestRoomCount">
                      {selectedBedsCount} Bed
                    </div>
                  </div>
                </div>
              </div>
              <div className="c-abzw2p"></div>
            </div>
            <div className="c-2pbfgc bkng-classic-box">
              <div className="c-1953uyt">
                <span className="c-1o86iao">
                  <MdVerticalShades />
                </span>
                <div>
                  <span className="c-1w0k317">Classic</span>
                </div>
              </div>
            </div>
            <div className="c-1jbawm2 bkng-room-details">
              <div className="c-18axi1k">
                <div className="c-6xf37g">Per Bed Charges</div>
                <div className="c-tvy9fi">200 Rs</div>
              </div>
              <div className="c-18axi1k">
                <div className="c-6xf37g">Selected Bed Quantity</div>
                <div className="c-tvy9fi">{selectedBedsCount}</div>
              </div>
              <div className="c-18axi1k">
                <div className="c-6xf37g">Booking price per bed</div>
                <div className="c-tvy9fi">{selectedBedsCount} bed X 200 Rs</div>
              </div>
              <div className="c-bjdcis"></div>
              <div className="c-18axi1k">
                <div className="c-dgqod7">
                  <span>Payable Amount</span>
                  <div className="c-m8gzcb">inclusive of all taxes</div>
                </div>
                <div className="c-11tk0uk">{totalPrice} Rs</div>
              </div>
              <div className="c-18axi1k">
                <button
                  type="button"
                  className="book-now-pay-btn"
                  onClick={openRazorpay}
                >
                  Book Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default BookingRightSection;
