import React, { useState } from "react";
import "./HostelPackages.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { endpoints } from "../../Services/endpoints";
import axios from "axios";

import parse from "html-react-parser";
const HostelPackages = () => {
  const [ownerPackage, setOwnerPackages] = useState([]);

  useEffect(() => {
    const packagesUrl = endpoints.home.packages;

    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
    };

    axios
      .get(packagesUrl, { headers: headers })
      .then((res) => {
        if (res.data.status === 1) {
          var packagesData = res.data.data;
          setOwnerPackages(packagesData);
          
        }
        else {
          console.error("Failed to fetch packages:", res.data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching packages:", error);
      });
  }, []);

  return (
    <>
      <div className="container-fluid p-0 popular-cont ">
        <div className="_category-page-all-hostels-section material-shadow-card-light">
          <div className="_block-header">
            <div className="category-page-list-heading">
              Packages For Owners
            </div>
          </div>
          <div className="wrapper-owner-packages">
            <div className="pricing-table">
            {ownerPackage.map((item, index) => (
              <div key={index} className="pricing-box">
                <h2 className="price">{item.package}</h2>
                {/* <span className="price">{item.price}/-</span> */}
                <div className="pricing-box-content-home-packages">{parse(item.content)}</div>
               
              </div>
            ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HostelPackages;
