import React, { useState } from "react";

import "./FooterSticky.css";
import { AiOutlineHome, AiOutlineGift, AiOutlineHeart } from "react-icons/ai";
import { GiBigDiamondRing } from "react-icons/gi";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { BsTelephone } from "react-icons/bs";
import { LuUserPlus, LuUser } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";
import FooterStickyModal from "./FooterStickyModal";
const FooterSticky = () => {
  const token = localStorage.getItem("token");
  const [isModalOpen, setIsModalOpen] = useState(false);
const navigate=useNavigate();
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const navigateToHome = () => {
  navigate("/")
  setIsModalOpen(false)
  };

  return (
    <>
      <div className="stiky_header_menu d-lg-none">
        <div className="row">
          <div className="col-2">
            <div className="link">
              <Link
                to="/"
                className="nav-link active font-weight-900 sticky-link"
                onClick={navigateToHome}
              >
                <span className="nav-icon">
                  <AiOutlineHome />
                </span>
                <span
                  className="sp"
                  style={{ fontWeight: "500", marginLeft: "4px" }}
                >
                  Home
                </span>
              </Link>
            </div>
          </div>
          <div className="col-2">
            <div className="link">
              <Link to="" className="nav-link font-weight-900 sticky-link">
                <span className="nav-icon">
                  <AiOutlineHeart />
                </span>
                <span className="sp" style={{ fontWeight: "500" }}>
                  Saved
                </span>
              </Link>
            </div>
          </div>
          <div className="col-2">
            <div className="link">
              <Link
                className="nav-link font-weight-900 sticky-link"
                to={token ? `/my-booking` : `/login-signup`}
              >
                <span className="nav-icon">
                  <HiOutlineShoppingBag />
                </span>
                <span className="sp" style={{ fontWeight: "500" }}>
                  Booking
                </span>
              </Link>
            </div>
          </div>
          <div className="col-4">
            <div className="link">
              <Link className="nav-link font-weight-900 sticky-link">
                <span className="nav-icon">
                  <LuUserPlus />
                </span>
                <span className="sp" style={{ fontWeight: "500" }}>
                  Contact
                </span>
              </Link>
            </div>
          </div>
          <div className="col-2">
            <div className="link">
              {token ? (
                <Link
                  // to=""
                  className="nav-link font-weight-900 sticky-link"
                  onClick={toggleModal}
                >
                  <span className="nav-icon">
                    <LuUser />
                  </span>
                  <span className="sp" style={{ fontWeight: "500" }}>
                    Account
                  </span>
                </Link>
              ) : (
                <Link
                  to="/login-signup"
                  className="nav-link font-weight-900 sticky-link"
                >
                  <span className="nav-icon">
                    <LuUser />
                  </span>
                  <span className="sp" style={{ fontWeight: "500" }}>
                    Login
                  </span>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && <FooterStickyModal onClose={toggleModal} />}
    </>
  );
};

export default FooterSticky;
