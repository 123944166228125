import React from "react";
import "./Covid.css";

const Covid = () => {
  return (
    <>
      <div className="covid-box">
        Please check the travel advisory issued by the concerned state
        government/local authorities before booking, as some places may have
        COVID-19 related travel/lodging restrictions.
      </div>
    </>
  );
};

export default Covid;
