import React, { useState } from "react";
import "./BookingLeftSection.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const BookingLeftSection = () => {
  const userData = JSON.parse(localStorage.getItem("userdata")) || {};

  return (
    <>
   

 
      <div className="c-12qt2ez bookin-left-top">
        <span>
          <span role="img" aria-label>
            🎉
          </span>
          <span>Yay! you just saved ₹0 on this booking!</span>
        </span>
      </div>
      <div className="c-8kaq9v booking-left-main-section">
        <div className="c-0">
          <div>
            <div className="c-i9gwvb left-enter-details">
              <div className="c-1kz1i96">1</div>
              <div className="c-i9gxme">
                <span>Check your details</span>
              </div>
            </div>
          </div>
          <div className="1rhsl0p booking-form">
            <div className="c-mjbegz booking-form-box">
              <div className="c-1s0ylt3">
                <span>
                  We will use these details to share your booking information
                </span>
              </div>
              <div className="c-qln3k6">
                <div className="textInput__container">
                  <div className="textInput__inputLabel" htmlFor="name">
                    <span>Full Name</span>
                  </div>
                  <input
                    className="textInput__input"
                    id="name"
                    placeholder="Enter first and last name"
                    type="text"
                    maxLength="50"
                    value={userData.name}
                    readOnly
                  />
                </div>
              </div>
              <div className="c-qln3k6">
                <div className="textInput__container">
                  <div className="textInput__inputLabel" htmlFor="name">
                    <span>Email Address</span>
                  </div>
                  <input
                    className="textInput__input"
                    id="name"
                    placeholder="Enter your email id"
                    type="text"
                    maxLength="50"
                    value={userData.email} 
                    readOnly
                  />
                </div>
              </div>
              <div className="c-qln3k6">
                <div className="textInput__container">
                  <div className="textInput__inputLabel" htmlFor="name">
                    <span>Mobile Number</span>
                  </div>
                  <input type="number"
                   className="textInput__input"
                    value={userData.phone} 
                    readOnly
                  />
                 
                </div>
              </div>
              {/* <div className="c-qln3k6">
               <div className="c-1ogcbvc">
                <button className="c-8cq10" type="button">
                    <span>Send Passcode</span>
                </button>
               </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default BookingLeftSection;
