import React from "react";
import "./MyBookingCard.css";
import Wizard from "./../../../assets/images/letterw.png";
import HostelMoneyCard from "../HostelMoneyCard/HostelMoneyCard";
import hostel from "./../../../assets/images/h5.jpg";
const MyBookingCard = () => {
  return (
    <>
      <div className="my-booking-card c-it9yxr">
        <div className="my-booking-card-box ">
          <div className="logo-wizard">
            <span className="c-17anid6-icon">
              <img src={Wizard} />
            </span>
          </div>
          <div className="my-booking-wizard-heading .c-k008qs ">
            <div className="c-nfd45z-text">
              <span>Hostel Wizard</span>
            </div>
          </div>
          <div className="c-ayr394-text">
            <div className="dataContainer">
              <div className="data-container-text-wizard">
                <span>
                  Hostel Wizard is our hospitality membership program. As a
                  member, you will be entitled to additional discounts of up to
                  10% on Hostels Wizard member hostels and will also enjoy
                  exclusive benefits with our partner alliances.
                </span>
              </div>
            </div>
          </div>
          <div className="c-fj084q-btn">
            <button type="button">
              <span>Buy Membership</span>
            </button>
          </div>
        </div>
      </div>
   
     
    </>
  );
};

export default MyBookingCard;
