import React from "react";
import "./ContactUsScreen.css";
import HomeHeader from "../../Common/HomeHeader/HomeHeader";
import Footer from "../../Common/Footer/Footer";
import { Link } from "react-router-dom";
import ContactUsCard from "../../Component/ContactUs/ContactUsCard";
const ContactUsScreen = () => {
  return (
    <>
      <div className="contact-us-screen">
        <HomeHeader />
        <nav aria-label="breadcrumb" className="main-breadcrumb mt-2">
          <div className="blog-screen-heading">
            <h2 className="blog-screen-heading-text">Contact Us</h2>
            <p className="blog-screen-sub-text">
              <span>
                <Link to="/">Home</Link>
              </span>
              &nbsp;<span>/</span>&nbsp;<span>contact us</span>
            </p>
          </div>
        </nav>
        <ContactUsCard/>
        <Footer />
      </div>
    </>
  );
};

export default ContactUsScreen;
