import React, { useEffect, useState } from "react";
import "./HostelDetailsCarousel.css";
import { AiOutlineStar, AiOutlinePlus } from "react-icons/ai";
import { Carousel } from "react-responsive-carousel";
import { GrNext, GrPrevious } from "react-icons/gr";

const HostelDetailsCarousel = (props) => {
  const { hostelImage } = props;
  const [selectedImage, setSelectedImage] = useState(hostelImage);
  const [currentIndex, setCurrentIndex] = useState(0);

 

  return (
    <>
      <div className="thumbnail-slider">
        <Carousel
          showArrows={true}
          showStatus={false}
          showThumbs={true}
          autoPlay={true}
          selectedItem={currentIndex}
        >
          {hostelImage?.map((image, index) => (
            <div key={index} className="responsive-crousel-slider-img">
              <img src={image} alt={`Slide ${index + 1}`} />
            </div>
          ))}
        </Carousel>
        {/* <div className="controls">
          <button onClick={handlePrev} className="responsive-slide-prev">
            <GrPrevious />
          </button>
          <button onClick={handleNext} className="responsive-slide-next">
            <GrNext />
          </button>
        </div> */}
      </div>
    </>
  );
};

export default HostelDetailsCarousel;
