import React from 'react'
import './WhatWeDo.css'
import WeImg from './WhatweDoImages/ceo.jpeg'
import parse from "html-react-parser";

const WhatWeDo = (props) => {
  const{allAboutData}=props;
const messageCeo=allAboutData[0]?.what_we_do_content || " ";

  return (
    <>
    <section className="about-section">
  <div className="container">
    <div className="row">                
      <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
        <div className="inner-column">
          <div className="sec-title">
          
            
            {/* <h2>{allAboutData[0]?.message_title}</h2> */}
          </div>
          <div className="text">
        
          {parse(messageCeo)}
          </div>
          
         
        </div>
      </div>
      {/* Image Column */}
      <div className="image-column col-lg-6 col-md-12 col-sm-12">
        <div className="inner-column wow fadeInLeft">
          <div className="author-desc">
            <h2>SANDEEP</h2>
            <span>CEO & Founder</span>
          </div>
          <figure className="image-1"><a href="#" className="lightbox-image" data-fancybox="images"><img title="Rahul Kumar Yadav" src= {allAboutData[0]?.what_we_do_image} alt /></a></figure>
        </div>
      </div>
    </div>
   
  </div>
</section>
    </>
  )
}

export default WhatWeDo;