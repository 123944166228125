import React from "react";
import "./BlogDetailsScreens.css";
import HomeHeader from "./../Common/HomeHeader/HomeHeader";
import { Link, useLocation, useParams } from "react-router-dom";
import BlogDetails from "../Component/BlogInfo/BlogDetails/BlogDetails";
import Footer from "../Common/Footer/Footer";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
const BlogDetailsScreens = () => {
  const { id } = useParams();
  const location=useLocation()
  const { data } = location.state || {};
  console.log(data,'blog screen data details')

  const [blogDetailsTitle,setBlogDetailsTitle]=useState("");
  const [blogDetailsImage,setBlogDetailsImage]=useState("");
  const [blogDetailsDiscription,setBlogDetailsDiscription]=useState("");
const blogDetailsUrls=`https://admin.nearmehostel.com/api/blogs/${id}`;

const headers = {
  "Content-Type": "application/json; charset=utf-8",
};

axios
.get(blogDetailsUrls,{headers:headers})
.then((res)=>{
  if (res.data.status==1){

    var val=res.data.data;
    setBlogDetailsTitle(res.data.data?.title);
    setBlogDetailsImage(res.data.data?.image);
    setBlogDetailsDiscription(res.data.data?.description);
    console.log(val,"blogs details data")
  }


})
.catch((err)=>{
  console.log(err,"Blogs details api not response")
})

useEffect(() => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  scrollToTop();
 
}, []); 

  return (
    <>
     <HomeHeader />
      <div className="blog-details-screen">
       
        <div className="blog-details-screen-heading">
          <h2 className="blog-details-screen-heading-text">{blogDetailsTitle}</h2>
          <p className="blog-details-screen-sub-text">
            <span>
              <Link to="/">Home</Link>
            </span>
            &nbsp;<span>/</span>&nbsp;<span>{blogDetailsTitle}</span>
          </p>
        </div>
        <BlogDetails  blogDetailsTitle={blogDetailsTitle}
        blogDetailsImage={blogDetailsImage}
        blogDetailsDiscription={blogDetailsDiscription}
        />
        <Footer/>
      </div>
    </>
  );
};

export default BlogDetailsScreens;
