import React from 'react';
import './App.css'

import 'bootstrap/dist/css/bootstrap.min.css';


import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import HomeScreens from './Screens/HomeScreens';
import LoginScreens from './Screens/LoginScreens';
import HostelOptionScreen from './Screens/HostelOptionScreen';
import HostelDetailsScreen from './Screens/HostelDetailsScreen';
import BookingScreen from './Screens/BookingScreen';
import MyBooking from './Screens/MyBooking/MyBooking';
import AboutUsScreen from './Screens/AboutUsScreen';
import RefundPolicy from './Screens/RefundPolicy/RefundPolicy';
import TermsCondition from './Screens/TermsCondition/TermsCondition';
import BlogScreens from './Screens/BlogScreens';
import BlogDetailsScreens from './Screens/BlogDetailsScreens';
import CheckOutScreens from './Screens/CheckOutScreens';
import UserProfileScreen from './Screens/UserProfileScreen/UserProfileScreen';
import ContactUsScreen from './Screens/ContactUsScreen/ContactUsScreen';
import PrivacyPolicy from './Screens/PrivacyPolicy/PrivacyPolicy';


function App() {


  return (
    <div className="App">
      
      <Router>
        <Routes>
        <Route path="/" element={<HomeScreens />} />
          <Route path="/login-signup" element={<LoginScreens />} />
          <Route path="/hostel/:city" element={<HostelOptionScreen />} />
          <Route path="/hotsel-detail/:id" element={<HostelDetailsScreen />} />
          <Route path="/booking-detail" element={<BookingScreen />} />
          <Route path="/my-booking" element={<MyBooking />} />
          <Route path="/about-us" element={<AboutUsScreen />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route path="/blogs" element={<BlogScreens />} />
          <Route path="/blogs-details/:id" element={<BlogDetailsScreens />} />
          <Route path="/checkout" element={<CheckOutScreens />} />
          <Route path="/user-profile" element={<UserProfileScreen />} />
          <Route path="/contact-us" element={<ContactUsScreen />} /> 
        </Routes>
      </Router>
    </div>
  
  );
}

export default App;
