import axios from "axios";
import React, { useState } from "react";
import { endpoints } from "../../Services/endpoints";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const SignUp = (props) => {
  const { showLoginForm } = props;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const phoneNumber = localStorage.getItem("phoneNumber");
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const submitUserData = () => {
    const registerUrl = endpoints.home.register;

    if (!name.trim()) {
      setErrors({ name: "Please enter name" });
    } else if (name.trim().length < 3) {
      setErrors({ name: "your name is to short" });
    } else if (!email.trim() || !emailRegex.test(email)) {
      setErrors({ email: "Valid email is required" });
    } else {
      setErrors({});
      setLoading(true);
      const data = {
        name: name,
        email: email,
        phone: phoneNumber,
      };

      const headers = {
        "Content-Type": "application/json",
      };

      axios
        .post(registerUrl, data, { headers: headers })
        .then((res) => {
          if (res.data.status === 1) {
            // toast(res.data.message, { type: "success" });
            const responseData = res.data.student[0];
            // localStorage.setItem("userdata", responseData);

            if (responseData && responseData.token) {
              const token = responseData.token;

              localStorage.setItem("token", token);
              localStorage.setItem("userdata", JSON.stringify(responseData));
              setLoading(false);
              navigate("/");
              toast(res.data.message, { type: "success" });
            } else {
              console.log("Token not found in response data");
              setLoading(false);
              toast(res.data.message, { type: "warning" });
            }
          } else {
            toast(res.data.message, { type: "error" });
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error("Register API did not respond", err);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <div className="login-form-container">
        <div className="login-form-cont-head">
          <h1>Sign Up Form</h1>
        </div>
        <div className="login-form-cont-main">
          <p>
            Enter Your Name <span style={{ color: "red" }}>*</span>
          </p>
          <input
            type="text"
            placeholder="Username"
            className="input-field"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <p className="error-msg">{errors.name}</p>
          <br />
          <br />
          <p>
            Enter Your Email <span style={{ color: "red" }}>*</span>
          </p>
          <input
            type="email"
            placeholder="Email"
            className="input-field"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <br />
          <p className="error-msg">{errors.email}</p>
          <br />
          <br />
          <button
            className="signup-button"
            type="button"
            onClick={submitUserData}
            style={{ display: showLoginForm ? "none" : "inline-block" }}
          >
            {loading ? "Sign in..." : "Sign Up"}
          </button>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default SignUp;
