import React from "react";
import "./BookingHistory.css";
const BookingHistory = () => {
  return (
    <>
      <div className="booking-history c-15i5dq4 c-it9yxr-text" name="bookings">
        <div>
          <div className="booking-history-c-dnffwc">
            <span className="booking-history-heading">Booking History</span>
            <select></select>
          </div>
          <div className="booking-history-c-dnffwc">
            <span className="c-ev22md-error-text">
              Error while fetching the bookings.
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingHistory;
