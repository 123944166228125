import React from "react";
import "./Filter.css";
import styled from "styled-components";
import Location from "./Location";
import SideBar from "./SideBar";
import Collection from "./Collection";
import Category from "./Category";
import AccomodationType from "./AccomodationType";
import HostelFacilities from "./HostelFacilities";
const Filter = () => {
  const ComponentDiv = styled.div`
    border-bottom: 0.5px solid #f0f0f0;
    padding: 5% 0;
  `;

  return (
    <>
      <div className="filter-section">
        <ComponentDiv>
          <h6>Filters</h6>
          {/* <span>Popular locations in Nagpur, Maharashtra, India</span> */}
          {/* <SideBar /> */}
          {/* <Location /> */}
        </ComponentDiv>

        <ComponentDiv>
          <Collection />
        </ComponentDiv>
        <ComponentDiv>
          <Category />
        </ComponentDiv>
        <ComponentDiv>
          <AccomodationType />
        </ComponentDiv>
        <ComponentDiv>
          <HostelFacilities />
        </ComponentDiv>
      </div>
    </>
  );
};

export default Filter;
