import React from 'react'
import HomeHeader from '../Common/HomeHeader/HomeHeader';
import CheckOut from '../ChekOutPages/CheckOut';
import Footer from "../Common/Footer/Footer";
import './CheckOutScreens.css';
const CheckOutScreens = () => {
  return (
    <>
    <div className='check-out-screens'>
    <HomeHeader/>
    <CheckOut/>
    <Footer/>
    </div>
    </>
  )
}

export default CheckOutScreens;