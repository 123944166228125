import React, { useEffect, useState } from "react";
import "./HostelDetailsScreen.css";

import HomeHeader from "../Common/HomeHeader/HomeHeader";
import Slider from "../HostelPages/HostelDetail/Slider/Slider";
import HostelDetailsSection from "../HostelPages/HostelDetail/HostelDetailsSection/HostelDetailsSection";
import Footer from "../Common/Footer/Footer";
import HostelDetails2 from "../HostelPages/HostelDetail/HostelDetailsSection/HostelDetails2";
import axios from "axios";
import Locations from "../Home/Location/Locations";
import StickyHeaders2 from "../Common/StickyHeader/StickyHeaders2";
import { useParams } from "react-router-dom";

const HostelDetailsScreen = () => {
  const { id } = useParams();
  const [hostelTitle, setHostelTitle] = useState("");
  const [hostelOwnerId, setHostelOwnerId] = useState("");
  const [hostelId, setHostelId] = useState("");
  const [hostelReview, setHostelReview] = useState({});
  const [hostelLocation, setHostelLocation] = useState("");
  const [hostelLocationCity, setHostelLocationCity] = useState("");
  const [hostelDiscription, setHostelDiscription] = useState("");
  const [hostelPolicy, setHostelPolicy] = useState("");
  const [hostelFeature, setHostelFeature] = useState([]);
  const [hostelImage, setHostelImage] = useState([]);
  const [hostelMembership, setHostelMembership] = useState("");
  const [hostelType, setHostelType] = useState("");
  const [hostelVedio, setHostelVedio] = useState("");

  const [detailsData, setDetailsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }

        const hostelDetailUrl = `https://admin.nearmehostel.com/api/hostels/view/${id}`;
        const headers = {
          "Content-Type": "application/json; charset=utf-8",
          Accept:"application/json, text/plain, */*",
          Authorization: `Bearer ${token}`,
        };

        const res = await axios.get(hostelDetailUrl, { headers:headers });

        if (res.data.status === 1) {
          const hostelDetailVal = res.data.data;

          console.log(
            hostelDetailVal,
            "hostelDetailVal all hostel data details"
          );

          setDetailsData(hostelDetailVal);
          setHostelTitle(res.data.data?.hostel_name || "");
          setHostelOwnerId(res.data.data?.user_id || "");
          setHostelId(res.data.data?.id || "");
          setHostelLocation(res.data.data?.location || "");
          setHostelLocationCity(res.data.data?.city || "");
          setHostelDiscription(res.data.data?.hostel_description || "");
          setHostelPolicy(res.data.data?.hostel_policy || "");
          setHostelFeature(res.data.data?.hostel_features || []);
          setHostelImage(res.data.data?.hostel_images || []);
          setHostelMembership(res.data.data?.hostel_membership || "");
          setHostelType(res.data.data?.gender_type || "");
          setHostelVedio(res.data.data?.youtube_video_link || "");
          setHostelReview(res.data.data?.reviews || "");
        } else {
          console.error(
            "API response status is not successful:",
            res.data.message
          );
        }
      } catch (err) {
        console.error("Error fetching hostel details:", err);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    scrollToTop();
  }, []);

  return (
    <>
     <HomeHeader />
      <div className="hostel-screen-section-box">
       
        <Locations />
        <Slider hostelImage={hostelImage} />
        <HostelDetails2
          detailsData={detailsData}
          hostelTitle={hostelTitle}
          hostelLocation={hostelLocation}
          hostelLocationCity={hostelLocationCity}
          hostelDiscription={hostelDiscription}
          hostelPolicy={hostelPolicy}
          hostelFeature={hostelFeature}
          hostelImage={hostelImage}
          hostelMembership={hostelMembership}
          hostelType={hostelType}
          hostelVedio={hostelVedio}
          hostelOwnerId={hostelOwnerId}
          hostelId={hostelId}
          hostelReview={hostelReview}
          setHostelReview={setHostelReview}
        />
        <Footer />
      </div>
    </>
  );
};

export default HostelDetailsScreen;
