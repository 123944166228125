import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar, Container, Dropdown } from "react-bootstrap";
import ListProperty from "./../../assets/images/ListProp.svg";
import Logo from "./../../assets/images/logo.svg";
import Login from "./../../assets/images/login.png";
import Person from "./../../assets/images/person.png";
import Calling from "./../../assets/images/callicon.svg";
import axios from "axios";
import { endpoints } from "../../Services/endpoints";
import "./HomeHeader.css";
import { GiTempleGate } from "react-icons/gi";
import { BiSolidUserBadge } from "react-icons/bi";
import { PiUsersFourFill } from "react-icons/pi";
import { TbHelpSquareFilled } from "react-icons/tb";
import { LuLogOut } from "react-icons/lu";

const getUserDataFromLocalStorage = () => {
  try {
    const userdataString = localStorage.getItem("userdata");
    if (userdataString) {
      return JSON.parse(userdataString);
    }
  } catch (error) {
    console.error("Error parsing userdata from local storage:", error);
    localStorage.removeItem("userdata"); // Clear invalid data from localStorage
  }
  return {}; // Return empty object if userdata is not found or parsing fails
};

const HomeHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userdata = getUserDataFromLocalStorage();

  const handleHover = () => setIsOpen(true);
  const handleLeave = () => setIsOpen(false);
  const handleClick = () => setIsOpen(!isOpen);

  const handleLogout = async () => {
    if (token) {
      const logOutUrl = endpoints.home.logout;
      const headers = {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`,
      };

      try {
        const res = await axios.get(logOutUrl, { headers });
        if (res.data.status === 1) {
          localStorage.removeItem("token");
          localStorage.removeItem("userdata");
          navigate("/");
        }
      } catch (err) {
        console.log(err, "Logout API did not respond");
        // Optionally handle the error (e.g., show a message to the user)
      }
    }
  };



  return (
    <Navbar expand={false} className="bg-body-tertiary mb-0 sticky-top">
      <div
        style={{
          top: "0px",
          left: "0px",
          position: "absolute",
          height: "10px",
          width: "100%",
          background: "darkblue",
        }}
      ></div>
      <Container fluid className="nave-bar-home">
        <Navbar.Brand>
          <Link to="/">
            <img
              src={Logo}
              className="home-header-nave-sticy-logo"
              alt="logo"
            />
          </Link>
        </Navbar.Brand>
        <div className="nave-bar-home-header">
          <div className="login-home-heads">
            {token ? (
              <div className="promoCard__iconWrapper-login">
                <Dropdown
                  onMouseEnter={handleHover}
                  onMouseLeave={handleLeave}
                  onClick={handleClick}
                >
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="user-btn-login"
                  >
                    {userdata?.image ? (
                      <img
                        src={userdata?.image}
                        className="img-user-login-header"
                        alt="user"
                      />
                    ) : (
                      <img
                        src={Person}
                        className="img-user-login-header"
                        alt="user"
                      />
                    )}

                    <span
                      className="user-login-btn-name"
                      style={{ color: "black" }}
                    >
                      {userdata?.name}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu show={isOpen}>
                    <Dropdown.Item as={Link} to=""></Dropdown.Item>
                    <Dropdown.Item as={Link} to="/my-booking">
                      <span className="profile-icons">
                        <GiTempleGate />
                      </span>
                      <span className="profile-nave-item">My Booking</span>
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/user-profile">
                      <span className="profile-icons">
                        <BiSolidUserBadge />
                      </span>
                      <span className="profile-nave-item"> My Profile</span>
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/about-us">
                      <span className="profile-icons">
                        <PiUsersFourFill />
                      </span>
                      <span className="profile-nave-item"> About Us </span>
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/">
                      <span className="profile-icons">
                        <TbHelpSquareFilled />
                      </span>
                      <span className="profile-nave-item">Help</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleLogout}>
                      <span  className="profile-icons"><LuLogOut/></span>
                     <span className="profile-nave-item">Logout</span> 
                      </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : (
              <div className="promoCard__iconWrapper-login">
                <img src={Login} className="img-login-header" alt="login" />
                <span className="login-btn-home">
                  <Link to="/login-signup" style={{ color: "black" }}>
                    Login
                  </Link>
                </span>
              </div>
            )}
          </div>
          <div className="calls-home-heads">
            <div className="promoCard__iconWrapper">
              <span>
                <Link to="tel:+918650150468">
                  <img src={Calling} alt="call" />
                </Link>
              </span>
            </div>
            <div className="promoCard__contentWrapper">
              <div className="promoCard__text d-text16 is-fontBold">
                <span>
                  <Link to="tel:+918840816061">8840816061</Link>
                </span>
              </div>
              <div className="promoCard__subtext d-text14">
                <Link to="tel:+918840816061">Call us to Book now</Link>
              </div>
            </div>
          </div>
          <div className="property-home-heads">
            <div className="promoCard__iconWrapper">
              <span>
                <Link to="https://admin.nearmehostel.com/register"  target="_blank">
                  <img src={ListProperty} alt="list-property" />
                </Link>
              </span>
            </div>
            <div className="promoCard__contentWrapper">
              <div className="promoCard__text d-text16 is-fontBold">
                <span>
                  <Link to="https://admin.nearmehostel.com/register" target="_blank">
                    List your property
                  </Link>
                </span>
              </div>
              <div className="promoCard__subtext d-text14">
                <Link to="">Start earning in 30 mins</Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Navbar>
  );
};

export default HomeHeader;
