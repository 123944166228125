import React, { useEffect, useState } from "react";
import "./HostelOptionScreens.css";
import Covid from "../HostelPages/Covid/Covid";
import Filter from "../HostelPages/Filters/Filter";
import TopFilterBar from "../HostelPages/Filters/TopFilterBar";
import Offer from "../Component/Offer/Offer";
import HostelInfo from "../HostelPages/HostelInfo/HostelInfo";
import { useParams } from "react-router-dom";
import axios from "axios";
import StickyHeaders2 from "../Common/StickyHeader/StickyHeaders2";
import Footer from "../Common/Footer/Footer";
import PagignationHostelInfo from "../HostelPages/HostelInfo/PagignationHostelInfo";
import SuggestionHostelInfo from "../HostelPages/HostelInfo/SuggestionHostelInfo";
import FooterSticky from "../Common/FooterSticky/FooterSticky";
import Loader from "../utils/Loader";

const HostelOptionScreen = () => {
  // const [currentPage, setCurrentPage] = useState(1);
  const [cardData, setCardData] = useState([]);
  const [suggestionCardData, setSuggestionCardData] = useState([]);
  const [cityBanner, setCityBanner] = useState([]);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(true);
  const { city } = useParams();

  console.log(city, "hostel and city name");
  console.log(suggestionCardData, "suggestionCardData hostel and city name");

  useEffect(() => {
    const fetchData = async () => {
      const dataLocationHostelUrl = `https://admin.nearmehostel.com/api/hostels/by-city-name/${city}`;
      const token = localStorage.getItem("token");
      try {
        const res = await axios.get(dataLocationHostelUrl, {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `Bearer ${token}`,
          },
        });

        if (res.data.status === 1) {
          setCityBanner(res.data.data?.banner);
          setCardData(res.data.data?.hostel);
          setSuggestionCardData(res.data.data?.suggestions);
          setMsg(res.data.message);

          setLoading(false);
        }
      } catch (err) {
        console.error("Error fetching hostels by city", err);
        setLoading(false);
      }
    };
    fetchData();
  }, [city, suggestionCardData]);

  return (
    <>
         {loading ? (
        <Loader />
      ) : (
        <>
      <div className="hostel-screens">
      <StickyHeaders2 />
    
        <div className="hostel-screen-main">
          <Covid />
          <div className="main-section-hostel">
            <div className="left-section-hostel">
              <Filter />
            </div>
            <div className="right-section-hostel">
              <TopFilterBar msg={msg} />
              <Offer cityBanner={cityBanner} loading={loading} />
              {cardData &&
                cardData?.map((e) => (
                  <HostelInfo key={e.id} data={e} rating={e.reviews} />
                ))}

              {suggestionCardData && suggestionCardData.length > 0 ? (
                suggestionCardData.map((item) => (
                  <SuggestionHostelInfo
                    key={item.id}
                    data={item}
                    rating={item.reviews}
                  />
                ))
              ) : (
                <p>No hostel available</p>
              )}
            </div>
          </div>
        </div>
    
        <Footer />
        
      </div>
      <FooterSticky/>
      </>
      )}
    </>
  );
};

export default HostelOptionScreen;
