import React, { useEffect, useState } from "react";
import "./LoginScreens.css";

import Footer from "../Common/Footer/Footer";

import HomeHeader from "../Common/HomeHeader/HomeHeader";
import SignUp from "../Component/LoginSignup/SignUp";
import VerifyedOtp from "../Component/LoginSignup/VerifyedOtp";
import axios from "axios";
import { endpoints } from "../Services/endpoints";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const LoginScreens = () => {
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [buttonColor, setButtonColor] = useState("lightgrey");
  const [cursor, setCursor] = useState("no-drop");
  const [loading, setLoading] = useState(false);
  // const [otpSent, setOtpSent] = useState(false);
  // const token = document.head.querySelector('meta[name="csrf-token"]');
  const handleLoginClick = () => {
    if (phoneNumber.length == 10) {
      setShowLoginForm(true);
      setShowSignupForm(false);

      console.log("Verifying number...", phoneNumber);
    } else {
      // Display an error message or perform other actions for invalid input
    }
  };

  const handleSignupClick = () => {
    const senOtpUrl = endpoints.home.getOtp;

    if (phoneNumber.length < 10 || phoneNumber.length > 15) {
      toast("Invalid phone number", { type: "error" });
      return;
    }
    setLoading(true);
    const data = {
      phone: phoneNumber,
    };

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      // 'X-CSRF-TOKEN': token.content
      // 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    };

    axios
      .post(senOtpUrl, data, { headers: headers })
      .then((res) => {
        if (res.data.status === 1) {
          setShowVerifyOtp(true);
          setShowLoginForm(false);
          setShowSignupForm(false);
          localStorage.setItem("phoneNumber", phoneNumber);
          setLoading(false);
          toast(res.data.message, { type: "success" });
          // console.log(res.data.message, "Submitted succesfully");
        } else if (res.data.status === 2) {
          setShowVerifyOtp(true);
          setShowLoginForm(false);
          setShowSignupForm(false);
          localStorage.setItem("phoneNumber", phoneNumber);
          setLoading(false);
          toast(res.data.message, { type: "success" });
        } else {
          toast(res.data.message, { type: "error" });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "this is the error here send otp");
        setLoading(false);
      });
  };

  const handleInputChange = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/\D/g, "");
    inputValue = inputValue.slice(0, 10);
    setPhoneNumber(inputValue);

    // Update button color to green if the input is valid
    setButtonColor(inputValue.length === 10 ? "#15124b" : "lightgrey");
    setCursor(inputValue.length === 10 ? "pointer" : "no-drop");
  };

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    // Call the function
    scrollToTop();
  }, []);
  return (
    <>
      <HomeHeader />

      <div className="login-signup">
        <div className="container">
          <div className="box-1">
            <div className="content-holder">
              <h2>There’s a smarter way to NearMe Hostels around</h2>
              <h6>
                Sign up with your phone number and get exclusive access to
                discounts and savings on Nearme Hostels stays and with our many
                travel partners.
              </h6>
            </div>
          </div>

          <div className="box-2">
            {showLoginForm && (
              <div className="login-form-container">
                <div className="login-form-cont-head">
                  <h1>Login Form</h1>
                </div>
                <div className="login-form-cont-main">
                
                  <p>
                    Enter Mobile Number <span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    type="number"
                    placeholder="Mobile No."
                    className="input-field"
                    maxLength={10}
                    value={phoneNumber}
                    onChange={handleInputChange}
                  />

                  <br />
                  <br />
                  <button
                    className="login-button"
                    type="button"
                    disabled={loading || phoneNumber.length !== 10}
                    onClick={handleSignupClick}
                    style={{
                      display: showSignupForm ? "none" : "inline-block",
                      backgroundColor: buttonColor,
                      cursor: cursor,
                    }}
                  >
                    {loading ? "Verifying..." : "Verify Number"}
                  </button>
                </div>
                {/* <form action className="form_main">
                  <p className="heading">Login</p>
                  <div className="inputContainer">
                    <svg
                      className="inputIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="#2e2e2e"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914z" />
                    </svg>
                    <input
                      type="text"
                      className="inputField"
                      id="username"
                      placeholder="Username"
                    />
                  </div>
                  <div className="inputContainer">
                    <svg
                      className="inputIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="#2e2e2e"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                    </svg>
                    <input
                      type="password"
                      className="inputField"
                      id="password"
                      placeholder="Password"
                    />
                  </div>
                  <button id="button">Submit</button>
                  <a className="forgotLink" href="#">
                    Forgot your password?
                  </a>
                </form> */}
              </div>
            )}

            <div className="login-form-container">
              {showVerifyOtp && (
                <VerifyedOtp
                  showLoginForm={showLoginForm}
                  setShowLoginForm={setShowLoginForm}
                  showSignupForm={showSignupForm}
                  setShowSignupForm={setShowSignupForm}
                  setShowVerifyOtp={setShowVerifyOtp}
                  // handleVerifyOtpClick={handleVerifyOtpClick}
                  phoneNumber={phoneNumber}
                  setPhoneNumber={setPhoneNumber}
                  handleSignupClick={handleSignupClick}
                />
              )}
            </div>

            {showSignupForm && (
              <SignUp
                handleLoginClick={handleLoginClick}
                handleSignupClick={handleSignupClick}
                showLoginForm={showLoginForm}
                setShowLoginForm={setShowLoginForm}
                showSignupForm={showSignupForm}
                setShowSignupForm={setShowSignupForm}
                setShowVerifyOtp={setShowVerifyOtp}
                showVerifyOtp={showVerifyOtp}
                // handleVerifyOtpClick={handleVerifyOtpClick}
              />
            )}
            <ToastContainer />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default LoginScreens;
