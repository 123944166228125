import React, { useState } from "react";
import Bed from "./../../../assets/images/singlebed.png";
import "./Modal.css";
import SeatChartModal from "./SeatChartModal";

const Modal = ({ closeModal, isOpen, bedData,setIsOpen,hostelId,checkInDate,checkOutDate,detailsData }) => {
  const [isSeatChartOpen, setIsSeatChartOpen] = useState(false);
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [selectedBedFloor, setSelectedBedFloor] = useState(null);
console.log(bedData,"selected bed data model");

  
  const openSeatChartModal = (seat,floor) => {
    console.log("Opening Seat Chart Modal with seat:", seat);
    closeModal();
    setSelectedSeat(seat);
    setSelectedBedFloor(floor);
    setIsSeatChartOpen(true);
  };

  const closeSeatChartModal = () => {
    console.log("Closing Seat Chart Modal");
    setSelectedSeat(null);
    setSelectedBedFloor(null);
    setIsSeatChartOpen(false);
    // document.body.classList.remove("modal-open");
  };


  return (
    <>
      {isOpen && (
        <div className="modal seat-modal">
          <div className="modal-content seat-avilablity-modal-content">
            <div className="modal-seatVailibility-header">
              <h6 className="heding-text-seat-availble">Seat Available</h6>
              <span className="close" onClick={closeModal}>
                &times;
              </span>
            </div>
            {bedData?.map((bed, idx) => (
              <div className="seat-availability-first-box" key={idx}>
                <header className="first-header-seat-avilability">
                  <code className="first-header-seat-avilability-code1">
                    {bed.floor} Seat
                  </code>
                </header>
                <div className="first-floor-all-section-box">
                  {bed.seats.map((seat, seatIdx) => {
                    let numImages;
                    switch (seat.type) {
                      case "Single Bed":
                        numImages = 1;
                        break;
                      case "Double Bed":
                        numImages = 2;
                        break;
                      case "Triple Bed":
                        numImages = 3;
                        break;
                      default:
                        numImages = 1;
                    }
                    return (
                      seat.available > 0 && (
                        <section
                          className="first-header-seat-avilability-section"
                          key={seatIdx}
                        >
                          <code className="first-header-seat-avilability-code">
                            {[...Array(numImages)].map((_, imgIdx) => (
                              <img src={Bed} alt="bed" key={imgIdx} />
                            ))}
                            <h6>Available : {seat.available}</h6>
                            <p>Type : {seat.type}</p>

                            <button
                              className="select-bed"
                              onClick={() => openSeatChartModal(seat, bed.floor)}
                            >
                              Book Bed
                            </button>
                          </code>
                        </section>
                      )
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {isSeatChartOpen && (
        <SeatChartModal
          isOpen={isSeatChartOpen}
          closeModal={closeSeatChartModal}
          selectedSeat={selectedSeat}
          hostelId={hostelId}
          checkInDate={checkInDate}
          checkOutDate={checkOutDate}
          bedFloor={selectedBedFloor}
          detailsData={detailsData}
        />
      )}
    </>
  );
};

export default Modal;
