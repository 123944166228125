import React from "react";
import "./BlogsCards.css";
import BlogImg1 from "./../../../assets/hostelimg/blogimg1.jpeg";
import BlogImg2 from "./../../../assets/hostelimg/blogimg2.jpeg";
import BlogImg3 from "./../../../assets/hostelimg/blogimg3.jpeg";
import BlogImg4 from "./../../../assets/hostelimg/blogimg4.jpeg";
import BlogImg5 from "./../../../assets/hostelimg/blogimg5.jpeg";
import { Link, useNavigate } from "react-router-dom";
import Cards from "./Cards";
const BlogsCards = (props) => {
  const { blogData } = props;
 
  return (
    <>
      <div class="container-fluid py-5">
        {/* <h2 className="font-weight-bold mb-2">From the Shop</h2>
        <p className="font-italic text-muted mb-4">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt.
        </p> */}
        <div className="row pb-5 mb-4">
          {blogData.map((item, index) => {
            return (
              <>
               <Cards
               data={item}
              key={index}
               />
              </>
            );
          })}
                   
       
        </div>
      </div>
    </>
  );
};

export default BlogsCards;
