import React, { useState } from "react";
import "./RightSectionDetails.css";
import { CiDiscount1 } from "react-icons/ci";
import { MdVerticalShades } from "react-icons/md";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiPencil, BiCheck } from "react-icons/bi";
// import {ImCheckboxChecked} from "react-icons/im";
import W from "./../../../assets/images/w4.png";
import Bed from "./../../../assets/images/singlebed.png";
import { Link } from "react-router-dom";
import SeatAvaillability from "../HostelDetailsSection/SeatAvaillability";
import EnqModalForm from "./EnqModalForm";
import { endpoints } from "../../../Services/endpoints";

const RightSectionDetails = (props) => {
const { detailsData ,hostelOwnerId,hostelId } = props;
  const [showModal, setShowModal] = useState(false);
  
  const openModal = () => {
    setShowModal(true);
  };

 
  




  return (
    <>
      <div className="seat-avilability-common-card-box">
        <div className="seat-avilability-common-card ">
          <h4>Bed Availability</h4>
          <button className="btn-enq" onClick={openModal}><span>Enquiry</span></button>
        </div>
        <div className="bed-seating-price">
          <ul className="right-section-ul-li">
            <li>
              <img src={Bed} />{" "}
              <span className="bed-seating-price-text">
                {" "}
               Rs {detailsData?.single_bed_rent}/-
              </span>
            </li>
            <li>
              <img src={Bed} />
              <img src={Bed} />{" "}
              <span className="bed-seating-price-text"> 
                {" "}
                Rs{detailsData?.double_bed_rent}/-
              </span>
            </li>
            <li>
              <img src={Bed} /> <img src={Bed} /> <img src={Bed} />{" "}
              <span className="bed-seating-price-text">
                {" "}
                Rs{detailsData?.triple_bed_rent}/-
              </span>
            </li>
          </ul>
        </div>
        <div className="c-2xmewo">
          <div className="product_left_details_box">
  
            <SeatAvaillability hostelId={hostelId} detailsData={detailsData} />
          </div>
        </div>
      </div>

      {showModal && (
        <EnqModalForm showModal={showModal} setShowModal={setShowModal} hostelId={hostelId} hostelOwnerId={hostelOwnerId}/>
      )}

    </>
  );
};

export default RightSectionDetails;
