import React, { useEffect } from "react";
import "./HostelMoneyCard.css";
import Hostel from "./../../../assets/images/h5.jpg";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Tooltip as ReactTooltip } from "react-tooltip";
import $ from "jquery";

const HostelMoneyCard = () => {
  return (
    <>
      <div className="my-booking-money-card c-it9yxr">
        <div className="my-booking-money-card-box ">
          <div className="logo-money">
            <span className="c-17anid6-icon-money">
              <img src={Hostel} />
            </span>
          </div>
          <div className="my-booking-money-heading .c-k008qs ">
            <div className="c-nfd45z-text-money">
              <span>Your Hostel Money</span>
            </div>
            <div className="c-qu0bs3">
              <span
                data-tooltip-id="hostel-money-tooltip"
                className="infoStateIconClass"
              >
                <AiOutlineInfoCircle />
              </span>
              <ReactTooltip
                className="infoStateIconData"
                id="hostel-money-tooltip"
                place="bottom"
                // content=""
              >
                <p className="hostel-money-tooltip-paragraph">
                  Spend less. Travel More.
                </p>
                Hostel Money makes it easy to save on every trip. Earn Hostel
                Money with every stay and then spend it on Hostels or with one
                of our partners to make your trip unforgettable.
              </ReactTooltip>
            </div>
          </div>
          <div className="mybooking-money-containt-box c-ayr394 ">
            <div className="c-d62dg3">
                <div>
                    <span>Hostel Money Balance</span> :&nbsp;
                    <strong>₹1500</strong>
                </div>
                <div>
                    <span>Expires</span> :&nbsp; 
                    <strong>Aug 11, 2023</strong>
                </div>
                <div>
                    <span>Usable this month</span> :&nbsp;
                    <strong>₹1500 <span>(max ₹25000 per month)</span></strong>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HostelMoneyCard;
