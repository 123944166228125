import React from "react";
import "./Slider.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Slider = (props) => {
  const { hostelImage } = props;

  console.log(hostelImage,"hostelImage")
  const options = {
    loop: true,
    margin: 10,
    items: 1,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
        nav: true,
      },
      300: {
        items: 1,
        nav: false,
      },
      600: {
        items: 1,
        nav: false,
      },
      1000: {
        items: 2,
        nav: true,
        loop: false,
      },
    },
  };

  return (
    <div className="Hostel-details-slider">
      <OwlCarousel loop margin={10} nav {...options}>
        {hostelImage?.map((item, index) => (
          <div key={index} className="item hostel-details-item">
            <div className="hostel-details-img-slid">
              <img src={item} alt={index} />
            </div>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
};

export default Slider;
