import React from "react";
import BookingNavbar from "../Component/BookingInfo/BookingNavbar/BookingNavbar";
import "./BookingScreen.css";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import BookingLeftSection from "../Component/BookingInfo/BookingLeftSection/BookingLeftSection";
import Footer from "../Common/Footer/Footer";
import BookingRightSection from "../Component/BookingInfo/BookingRightSection/BookingRightSection";
import { useLocation } from "react-router-dom";

const BookingScreen = () => {
  const location = useLocation();
  const {
    hostelId,
    checkInDate,
    checkOutDate,
    bedFloor,
    selectedSeat,
    ownerId,
    selectedBeds,
    selectedRooms,
    detailsData,
    totalPrice,
  
    
  } = location.state || {};

 console.log(selectedRooms,"selectedRooms")

  return (
    <>
      <div className="bokking-screen">
        <div className="booking-screen-main-section">
          <BookingNavbar />
          <div className="booking-screen-container-box">
            <div className="booking-modify-section">
              <div className="c-1vrj4pl">
                {/* <span className="c-1aivkbe">
                <MdOutlineArrowBackIosNew />
              </span>{" "} */}
                &nbsp;
                {/* <span className="c-isphd1">
                <span>Modify your booking</span>
              </span> */}
              </div>
            </div>

            <div className="c-9ugfym book-now-section">
              <div className="c-172kp3s book-left-box">
                <BookingLeftSection />
              </div>
              <div className="book-right-box"></div>

              <BookingRightSection
                detailsData={detailsData}
                hostelId={hostelId}
                checkInDate={checkInDate}
                checkOutDate={checkOutDate}
                bedFloor={bedFloor}
                selectedSeat={selectedSeat}
                ownerId={ownerId}
                selectedBeds={selectedBeds}
                totalPrice={totalPrice}
                selectedRooms={selectedRooms}
              />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default BookingScreen;
