import React from "react";
import "./ContactUsCard.css";
import { TfiLocationPin } from "react-icons/tfi";
import { LuPhoneCall } from "react-icons/lu";
import { MdOutlineMail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";

const ContactUsCard = () => {
  return (
    <>
      <div className="container">
        <div className="main-body">
          <div className="row gutters-sm">
            <div className="col-md-3 mb-3">
              <div className="card">
                <div className="card-body">
                  <span className="contact-us-mark-img">
                    <TfiLocationPin />
                  </span>
                  <h6 className="contact-us-card-heading">Our Main Office</h6>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card">
                <div className="card-body">
                  <span className="contact-us-mark-img">
                    <LuPhoneCall />
                  </span>
                  <h6 className="contact-us-card-heading">Call</h6>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card">
                <div className="card-body">
                  <span className="contact-us-mark-img">
                    <MdOutlineMail />
                  </span>
                  <h6 className="contact-us-card-heading">Email</h6>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card">
                <div className="card-body">
                  <span className="contact-us-mark-img">
                    <FaWhatsapp />
                  </span>
                  <h6 className="contact-us-card-heading">Whats App</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="main-body">
          <div className="card">
            <div className="card-body">
              <div className="row gutters-sm">
                <div className="col-md-6">

                </div>
                <div className="col-md-6">
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUsCard;
