import React from 'react'
import { Link, useNavigate } from 'react-router-dom';

const Cards = (props) => {
    const {data,key}=props
    const navigate = useNavigate();
    const blogDetails = () => {
      navigate(`/blogs-details/${data.id}`);
    };
  return (
    <>
 <div
                  className="col-lg-3 col-md-6 mb-4 mt-5 mb-lg-0"
                  key={key}
                >
                  <div
                    className="card rounded shadow-sm border-0"
                    onClick={blogDetails}
                    style={{cursor:"pointer"}}
                  >
                    <div className="card-body p-2">
                      <div className="blog-cards-img-box">
                        <img
                          src={data.image}
                          alt
                          className="img-fluid d-block mx-auto mb-3"
                        />
                      </div>
                      <div className="blog-cards-title">
                        <h5>
                          {" "}
                          <Link to="" className="text-dark">
                            {data.title}
                          </Link>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
    </>
  )
}

export default Cards;