import React, { useEffect, useState, useRef } from "react";
import "./SearchBar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { endpoints } from "../../Services/endpoints";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MAX_SUGGESTIONS = 10;
const SearchBar = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [dataNotFound, setDataNotFound] = useState(false);
  const [errors, setErrors] = useState({});
  


 

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setErrors({});
    if (value.trim() !== "") {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
      setSuggestions([]);
      setDataNotFound(false);
    }
  };

  const handleSelectChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    setErrors({});
  };

  useEffect(() => {
    if (inputValue.trim() !== "") {
      fetchSuggestions();
    }
  }, [inputValue]);

  const fetchSuggestions = async () => {
    const suggestionsUrl = endpoints.home.suggestions;
    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
    };

    try {
      const res = await axios.get(suggestionsUrl, { headers: headers });
      if (res.data.status === 1) {
        const { cities, hostels } = res.data.data;
        const mergedData = [...cities, ...hostels];

        const filteredSuggestions = mergedData.filter((item) =>
          `${item.city} ${item.hostel_name}`
            .toLowerCase()
            .includes(inputValue.toLowerCase())
        );

        // Show "Data not found" message if there are no suggestions
        setDataNotFound(filteredSuggestions.length === 0);

        setSuggestions(filteredSuggestions.slice(0, MAX_SUGGESTIONS));
      }
    } catch (error) {
      console.error("There was a problem with the Axios operation:", error);
      // Handle error here
    }
  };

  const handleSuggestionClick = (selectedSuggestion) => {
    const { city, hostel_name } = selectedSuggestion;
    if (city) {
      setInputValue(city);
    } else if (hostel_name) {
      setInputValue(hostel_name);
    }
    setSuggestions([]);
    setShowSuggestions(false);
    setDataNotFound(false);
  };

  //implement serch bar api

  const searchHome = () => {
    const searchUrl = endpoints.home.searching;
    if (!inputValue.trim()) {
      setErrors({ inputValue: "Please enter hostel name or city" });
      toast.error("Please enter hostel name or city");
    } else if (!startDate) {
      setErrors({ startDate: "Please choose date" });
      toast.error("Please choose date");
    } else if (selectedOption === "") {
      setErrors({ selectedOption: "Please select hostel type" });
      toast.error("Please select hostel type");
    } else {
      setErrors({});
      const data = {
        city_or_hostel: inputValue,
        date: startDate,
        gender: selectedOption,
      };
      const headers = {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        // "X-CSRF-TOKEN": csrfToken,
      };
      axios
        .post(searchUrl, data, { headers: headers })
        .then((res) => {
          if (res.data.status === 1) {
            const { city_or_hostel } = data;
            console.log(city_or_hostel, "city_or_hostel");
            // navigate(`/hostel/${city_or_hostel}`);
            navigate(`/hostel/${city_or_hostel.replace(/\s+/g, "_")}`);
          } else {
            setDataNotFound(true);
          }
        })
        .catch((err) => {
          console.error(err, "search api not responding");
        });
    }
  };
  //implement serch bar
  return (
    <div className="container-fluid serch-cont-fluid">
      <div className="red-rectangle">
        <p className="heading-red-rectangle">
          Over 157,000 Hostels and PG across 35 Cities
        </p>

        <div className="search-bar-home-page">
          <div className="container serch-bar-cont">
            <div className="row">
              <div className="col-lg-5 col-sm-12 serch-col">
                <div className="search-field">
                  <input
                    type="text"
                    placeholder="Search by city, hostel"
                    className="search-input-hostel"
                    value={inputValue}
                    onChange={handleInputChange}
                  />

                  {showSuggestions && suggestions.length > 0 && (
                    <ul className="suggestions-list">
                      {suggestions.map((item, index) => (
                        <li
                          key={index}
                          className="suggestion-list-text-box"
                          onClick={() => handleSuggestionClick(item)}
                        >
                          {item.city} {item.hostel_name}
                        </li>
                      ))}
                    </ul>
                  )}

                  {dataNotFound && inputValue.trim() !== "" && (
                    <p className="no-data-found">Data not found</p>
                  )}
                  <div className="error-msg">{errors.inputValue}</div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12 serch-col">
                <DatePicker
                  className="search-bar-date-input"
                  placeholderText="Please select a date"
                  selected={startDate}
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                />
               
                
                <div className="error-msg">{errors.startDate}</div>
              </div>
              <div className="col-lg-3 col-sm-12 serch-col">
                <Form.Select
                  value={selectedOption}
                  onChange={handleSelectChange}
                  className="search-bar-select"
                >
                  <option value="">--- select type ---</option>
                  <option value="boys">Boys</option>
                  <option value="girls">Girls</option>
                  {/* <option value="common">Common</option> */}
                </Form.Select>
                <div className="error-msg">{errors.selectedOption}</div>
              </div>
              <div className="col-lg-1 col-sm-12 serch-col">
                <button onClick={searchHome} className="search-btn-home-page">
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer/>
      </div>
    </div>
  );
};

export default SearchBar;
