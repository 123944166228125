import React from "react";
import Logo from "./../../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import './BookingNavbar.css';
const BookingNavbar = () => {
  const navigate = useNavigate();
  return (
    <>
      <header className="booking-nave-sticky">
        <div className="booking-logo-box">
        <img
          onClick={() => {
            navigate("/");
          }}
          src={Logo}
          alt="Union"
          className="booking-logo"
          style={{ cursor: "pointer" }}
        />
        </div>
       
        {/* <div className="nav-sticky-container"></div> */}
      </header>
    </>
  );
};

export default BookingNavbar;
