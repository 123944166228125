const BASE_URL = "https://admin.nearmehostel.com/api";
export const endpoints={
home:{
    city: BASE_URL + "/cities",
    slider:BASE_URL+"/sliders",
    aboutus:BASE_URL+"/about",
    popularHostels:BASE_URL+"/popular-hostels",
    blogs:BASE_URL+"/blogs",
    enquiry:BASE_URL+"/student/store-enquiry",
    getOtp:BASE_URL+"/getOtp",
    verifyOtp:BASE_URL+"/verifyOtp",
    register:BASE_URL+"/register",
    logout:BASE_URL+"/logout",
    refundPolicy:BASE_URL+"/refund-policy",
    privacyPolicy:BASE_URL+"/privacy-and-policy",
    termsConditions:BASE_URL+"/terms-and-condition",
    packages:BASE_URL+"/packages",
    suggestions:BASE_URL+"/hostels/get-suggestion",
    searching:BASE_URL+"/hostels/by-city-hostel-date-gender",
    review:BASE_URL+"/student/store-review",
    bedAvailability:BASE_URL+"/hostels/get-available-beds",
    bedAvailabilityData:BASE_URL+"/hostels/get-available-data",
    profile:BASE_URL+"/student/dashboard",


}
}