import React from "react";

import "./Footer.css";
import Logo from "./FooterImages/logo.png";
import AppStore from "./FooterImages/appstore.png";
import PlayStore from "./FooterImages/playstored.png";
import Property from "./FooterImages/property.png";
import { Link } from "react-router-dom";
import { BiBuildingHouse  } from "react-icons/bi";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer-sec1">
          <div>
            <img src={Logo} alt="UnionWhite" />
            <p className="footer-logo-text">
              The World’s Fastest Growing Hostel Chain
            </p>
          </div>
          <div className="footer-property-btn-div">
            <p className="footer-logo-text2">
              Join our network and grow your business!
            </p>
            <Link to="https://admin.nearmehostel.com/register" className="fotter-property-button">
             
              <span><i className="property-icon"><BiBuildingHouse  /></i><i className="property-icon-text">List your property</i></span></Link>
            {/* <button>
              <img src={Property} alt="property" />
             <Link to=""> List your property</Link>
             
            </button> */}
          </div>
        </div>
        <div className="footer-sec2">
          <div className="about-footer">
            <h6 className="about-footer-heading-txt">About NearMeHostels.com</h6>
            <p>
              About NearMeHostels.com The main purpose of this site is to
              provide you a good and affordable Hostel & PG. You can inquire and
              also book the Hostel & P.G from this site in any City, location,
              Price enrolled in this site. No Brokerage Charge and Free Hostel
              Visit. We provide you surety about the rooms and services shown on
              this site are Real and Genuine... Know More...
            </p>
          </div>
          <div>
            <p><Link to="/about-us">About Us</Link></p>
            <p>Teams / Careers</p>
            <p><Link to="/blogs">Blogs</Link></p>
            <p>Support</p>
            <p><Link to="/refund-policy">Refund Policy</Link></p>
            <p><Link to="/terms-condition">Terms and Conditions</Link></p>
            <p>Hostel Circle</p>
            <p>Hostel Frames</p>
          </div>
          <div>
            <p>Terms and conditions</p>
            <p>Guest Policies</p>
            <p><Link to="/privacy-policy">Privacy Policy</Link></p>
            <p>Responsible Disclosure</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
