import React, { useState } from "react";
import "./UserProfileScreen.css";
import HomeHeader from "../../Common/HomeHeader/HomeHeader";
import Footer from "../../Common/Footer/Footer";
import UserProfileCard from "../../Component/UserProfile/UserProfileCard";
import UserProfileForm from "../../Component/UserProfile/UserProfileForm";
import DemoImage from "../../assets/images/imgnotfound.png";
import { FaWhatsapp } from "react-icons/fa";
import { IoCallOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { endpoints } from "../../Services/endpoints";
import axios from "axios";
const UserProfileScreen = () => {
  const [profileData, setProfileData] = useState([]);
  const [roomData, setRoomData] = useState([]);
  const [roomNo, setRoomNo] = useState("");
  const [roomRents, setRoomRents] = useState("");
  const [securityAmount, setSecurityAmount] = useState("");

  useState(() => {
    const profileUrl = endpoints.home.profile;
    const token = localStorage.getItem("token");

    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios

      .get(profileUrl, { headers: headers })
      .then((res) => {
        if (res.data.status === 1) {
          setProfileData(res.data.data);
          const bookingData = res.data.data?.booking;
          const roomName = bookingData?.find((item) => item.room_name)
            ?.room_name;
          const roomRent = bookingData?.find((item) => item.rent)?.rent;
          const SecurityAmounts = bookingData?.find(
            (item) => item.security_amount
          )?.security_amount;
          setSecurityAmount(SecurityAmounts);
          setRoomRents(roomRent);
          setRoomNo(roomName);
          setRoomData(bookingData);
          console.log(res.data.data, "profile data");
          console.log(res.data.data?.booking, "room data");
        }
      })
      .catch((err) => {
        console.error(err, "profile api not responding");
      });
  }, []);

  return (
    <>
      <div className="user-profile-screen">
        <HomeHeader />
        <nav aria-label="breadcrumb" className="main-breadcrumb mt-2">
          <div className="blog-screen-heading">
            <h2 className="blog-screen-heading-text">Profile</h2>
            <p className="blog-screen-sub-text">
              <span>
                <Link to="/">Home</Link>
              </span>
              &nbsp;<span>/</span>&nbsp;<span>Profile</span>
            </p>
          </div>
        </nav>

        <div className="container">
          <div className="main-body">
            {/* Breadcrumb */}

            {/* /Breadcrumb */}
            <div className="row gutters-sm">
              <div className="col-md-4 mb-3">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex flex-column align-items-center text-center">
                      <img
                        src={
                          profileData?.image
                            ? profileData.image
                            : "https://bootdey.com/img/Content/avatar/avatar7.png"
                        }
                        alt="Admin"
                        className="rounded-circle"
                        width={150}
                      />
                      <div className="mt-3">
                        <h4>{profileData?.name}</h4>
                        <p className="text-secondary mb-1">
                          {profileData?.role}
                        </p>
                        {/* <p className="text-muted font-size-sm">
                          Bay Area, San Francisco, CA
                        </p> */}
                        <button className="btn btn-outline-primary">
                          <IoCallOutline />
                        </button>{" "}
                        <button className="btn btn-outline-primary">
                          <FaWhatsapp />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mt-3">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                      <h6 className="mb-0">Room No.</h6>
                      <span className="text-secondary">{roomNo}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                      <h6 className="mb-0">Date Of Joining</h6>
                      <span className="text-secondary">08-01-24</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                      <h6 className="mb-0">Security Deposit(Rs.)</h6>
                      <span className="text-secondary">{securityAmount}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                      <h6 className="mb-0">Rent(Rs.)</h6>
                      <span className="text-secondary">{roomRents}</span>
                    </li>
                  </ul>
                </div>
                <div className="card mt-3">
                  <div className="document-image-student">
                    <h4 className="user-profile-document-text">Documents</h4>
                    <hr />
                    <div className="row">
                      <div className="col-6">
                        <div className="user-profile-document-image-box">
                          <p>Govt. ID Front</p>
                          <img
                             src={profileData?.aadhar_front || DemoImage}
                            
                            className="user-profile-document-image"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="user-profile-document-image-box">
                          <p>Govt. ID Back</p>
                          <img
                            src={profileData?.aadhar_back || DemoImage}
                            className="user-profile-document-image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                {/* <UserProfileCard/> */}
                <UserProfileForm />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default UserProfileScreen;
