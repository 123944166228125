import React from 'react'
import Checkbox from "@mui/material/Checkbox";
import styled from "styled-components";
import './Category.css';
const Div = styled.div`
  display: flex;
  text-align: left;
  & > p {
    margin-top: 2%;
    font-weight: 500;
  }
  & span {
    color: darkgray;
    font-weight: 400;
  }
`;

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Category = () => {
  return (
    <>
 <p className='category-heding-txt'>Categories</p>
      <Div>
        <div>
          <Checkbox {...label} />
        </div>

        <p>
          Hostel Rooms -{" "}
          <span>Super affordable stays with essential amenities</span>
        </p>
      </Div>
      <Div>
        <div>
          <Checkbox {...label} />
        </div>
        <p>
          Townhouse -{" "}
          <span>
            Your friendly, premium neighbouhood hotel - Serviced by Hostel
          </span>
        </p>
      </Div>
      <Div>
        <div>
          <Checkbox {...label} />
        </div>
        <p>
          Flagship -{" "}
          <span>Affordable hotels at Premium locations - Serviced by hoste</span>
        </p>
      </Div>
    </>
  )
}

export default Category