import React from "react";
import "./FooterModalSticky.css";
import Logo from "../../assets/images/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { GiTempleGate } from "react-icons/gi";
// import { BiSolidUserBadge } from "react-icons/bi";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { PiUsersFourFill } from "react-icons/pi";
import { TbHelpSquareFilled } from "react-icons/tb";
import { LuLogOut } from "react-icons/lu";
import axios from "axios";
import { endpoints } from "../../Services/endpoints";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const getUserDataFromLocalStorage = () => {
  try {
    const userdataString = localStorage.getItem("userdata");
    if (userdataString) {
      return JSON.parse(userdataString);
    }
  } catch (error) {
    console.error("Error parsing userdata from local storage:", error);
    localStorage.removeItem("userdata"); // Clear invalid data from localStorage
  }
  return {}; // Return empty object if userdata is not found or parsing fails
};

const FooterStickyModal = ({ onClose }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userdata = getUserDataFromLocalStorage();
  const handleLogout = async () => {
    if (token) {
      const logOutUrl = endpoints.home.logout;
      const headers = {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`,
      };

      try {
        const res = await axios.get(logOutUrl, { headers });
        if (res.data.status === 1) {
          localStorage.removeItem("token");
          localStorage.removeItem("userdata");

          setTimeout(() => {
            onClose();
            navigate("/");
          }, 2000);
        }
        toast.success("Logout successful!");
      } catch (err) {
        console.log(err, "Logout API did not respond");
      }
    }
  };
  return (
    <>
      <div className="modal-overlay">
        <div className="modal account-modal">
          <div className="modal-header account-modal-header">
            {/* <h2>Account Modal</h2> */}
            <img src={Logo} alt="logo" className="account-modal-logo" />
            <button
              className="close-button account-modal-close-button"
              onClick={onClose}
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <div id="first" class="button-row">
              <Link to="/user-profile">
                <button class="upload comn-card-btn">
                  <p className="comn-card-icon">
                    {" "}
                    <GiTempleGate />
                  </p>
                  <h6>My Profile</h6>
                </button>
              </Link>
              <Link to="/my-booking">
                <button class="live-video comn-card-btn">
                  <p className="comn-card-icon">
                    <HiOutlineShoppingBag />
                  </p>
                  <h6>My Booking</h6>
                </button>
              </Link>
              <Link to="/about-us">
                <button class="life-event comn-card-btn">
                  <p className="comn-card-icon">
                    {" "}
                    <PiUsersFourFill />
                  </p>
                  <h6>About Us</h6>
                </button>
              </Link>

              <Link to="">
                <button class="people comn-card-btn">
                  <p className="comn-card-icon">
                    {" "}
                    <TbHelpSquareFilled />{" "}
                  </p>
                  <h6>Help</h6>
                </button>
              </Link>

              <button class="feeling comn-card-btn" onClick={handleLogout}>
                <p className="comn-card-icon">
                  <LuLogOut />{" "}
                </p>
                <h6>Logout</h6>
              </button>

              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterStickyModal;
