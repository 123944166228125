import React, { useEffect } from "react";
import "./StickyHeaders2.css";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ListProperty from "./../../assets/images/ListProp.svg";
import Logo from "./../../assets/images/logo.svg";
import Login from "./../../assets/images/login.png";
import Person from "./../../assets/images/person.png";
import Calling from "./../../assets/images/callicon.svg";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { GiTempleGate } from "react-icons/gi";
import { BiSolidUserBadge } from "react-icons/bi";
import { PiUsersFourFill } from "react-icons/pi";
import { TbHelpSquareFilled } from "react-icons/tb";
import { LuLogOut } from "react-icons/lu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { endpoints } from "../../Services/endpoints";
const MAX_SUGGESTIONS = 10;
const getUserDataFromLocalStorage = () => {
  try {
    const userdataString = localStorage.getItem("userdata");
    if (userdataString) {
      return JSON.parse(userdataString);
    }
  } catch (error) {
    console.error("Error parsing userdata from local storage:", error);
    localStorage.removeItem("userdata"); // Clear invalid data from localStorage
  }
  return {}; // Return empty object if userdata is not found or parsing fails
};

const StickyHeaders2 = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [searchResult, setSearchResult] = useState("");
 
  const [inputValue, setInputValue] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [dataNotFound, setDataNotFound] = useState(false);

  
  const [errors, setErrors] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const token = localStorage.getItem("token");
  const userdata = JSON.parse(localStorage.getItem("userdata") || "{}");

  const handleHover = () => {
    setIsOpen(true);
  };

  const handleLeave = () => {
    setIsOpen(false);
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = async () => {
    if (token) {
      const logOutUrl = endpoints.home.logout;
      const headers = {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`,
      };

      try {
        const res = await axios.get(logOutUrl, { headers });
        if (res.data.status === 1) {
          localStorage.removeItem("token");
          localStorage.removeItem("userdata");
          navigate("/");
        }
      } catch (err) {
        console.log(err, "Logout API did not respond");
        // Optionally handle the error (e.g., show a message to the user)
      }
    }
  };





  // Search bar code here

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (value.trim() !== "") {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
      setSuggestions([]);
      setDataNotFound(false);
    }
  };

  const fetchSuggestions = async () => {
    const suggestionsUrl = endpoints.home.suggestions;
    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
    };

    try {
      const res = await axios.get(suggestionsUrl, { headers: headers });
      if (res.data.status === 1) {
        const { cities, hostels } = res.data.data;
        const mergedData = [...cities, ...hostels];

        const filteredSuggestions = mergedData.filter((item) =>
          `${item.city} ${item.hostel_name}`
            .toLowerCase()
            .includes(inputValue.toLowerCase())
        );

        setDataNotFound(filteredSuggestions.length === 0);
        setSuggestions(filteredSuggestions.slice(0, MAX_SUGGESTIONS));
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setDataNotFound(true);
      setSuggestions([]);
    }
  };


  const handleSelectChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
  };

  useEffect(() => {
    if (inputValue.trim() !== "") {
      fetchSuggestions();
    }
  }, [inputValue]);



  const handleSuggestionClick = (selectedSuggestion) => {
    const { city, hostel_name } = selectedSuggestion;
    setInputValue(city || hostel_name || "");
    setSuggestions([]);
    setShowSuggestions(false);
    setDataNotFound(false);
  };



const searchHome = () => {
  const searchUrl = endpoints.home.searching;
  if (!inputValue.trim()) {
    toast.error("Please enter hostel name or city");
    return;
  }
  if (!startDate) {
    toast.error("Please choose date");
    return;
  }
  if (selectedOption === "") {
    toast.error("Please select hostel type");
    return;
  }

  const data = {
    city_or_hostel: inputValue,
    date: startDate,
    gender: selectedOption,
  };

  axios
    .post(searchUrl, data)
    .then((res) => {
      if (res.data.status === 1) {
        const { city_or_hostel } = data;
        const formattedCityOrHostel = city_or_hostel.replace(/\s+/g, "_");
        navigate(`/hostel/${formattedCityOrHostel}`);
      } else {
        setDataNotFound(true);
      }
    })
    .catch((err) => {
      console.error("Error searching for homes:", err);
      setDataNotFound(true);
    });
};


  // search bar code end here

  return (
    <>
      {[false].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className="bg-body-tertiary mb-0 sticky-top"
        >
          <div
            style={{
              top: "0px",
              left: "0px",
              position: "absolute",
              height: "10px",
              width: "100%",
              background: "darkblue",
            }}
          ></div>
          <Container fluid className="nave-bar-home">
            <Navbar.Brand className="sticky-nave-brand">
              <Link to="/">
                <img src={Logo} className="home-header-nave-sticy-logo" />
              </Link>
            </Navbar.Brand>
            <div className="nave-bar-home-header sticky-home-nave-header">
              <div className="login-home-heads">
                {token ? (
                  <div className="promoCard__iconWrapper-login">
                    <Dropdown
                      onMouseEnter={handleHover}
                      onMouseLeave={handleLeave}
                      onClick={handleClick}
                    >
                      <Dropdown.Toggle
                        id="dropdown-basic "
                        className="user-btn-login"
                      >
                        <img src={Person} className="img-user-login-header" />{" "}
                        <span className="user-login-btn-name">
                          {userdata?.name}
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu show={isOpen}>
                        <Dropdown.Item as={Link}></Dropdown.Item>
                        <Dropdown.Item as={Link} to="/my-booking">
                          <span className="profile-icons">
                            <GiTempleGate />
                          </span>
                          <span className="profile-nave-item">My Booking</span>
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/">
                          <span className="profile-icons">
                            <BiSolidUserBadge />
                          </span>
                          <span className="profile-nave-item"> My Profile</span>
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/about-us">
                        <span className="profile-icons">
                        <PiUsersFourFill />
                      </span>
                      <span className="profile-nave-item"> About Us </span>
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="">
                          <span className="profile-icons">
                            <TbHelpSquareFilled />
                          </span>
                          <span className="profile-nave-item">Help</span>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleLogout}>
                          <span className="profile-icons">
                            <LuLogOut />
                          </span>
                          <span className="profile-nave-item">Logout</span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                ) : (
                  <div className="promoCard__iconWrapper-login">
                    <div className="sticky-login-btn">
                      <img src={Login} className="img-login-header" />{" "}
                      <span className="login-btn-home">
                        <Link to="/login-signup" style={{ color: "black" }}>
                          Login
                        </Link>
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="header-search-bar details-headers-sticky">
                <div className="container sticky-nave-cont">
                  <div className="row">
                    <div className="col-lg-5 nav-sticky-col">
                      <input
                        type="text"
                        placeholder="Search by city, hostel"
                        className="search-input-hostel header-search-city"
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                        {showSuggestions && suggestions.length > 0 && (
                      <ul className="suggestions-list">
                        {suggestions.map((item, index) => (
                          <li
                            key={index}
                            className="suggestion-list-text-box"
                            onClick={() => handleSuggestionClick(item)}
                          >
                            {item.city} {item.hostel_name}
                          </li>
                        ))}
                      </ul>
                    )}
                    {dataNotFound && inputValue.trim() !== "" && (
                      <p className="no-data-found">Data not found</p>
                    )}
                    </div>
                    <div className="col-lg-3 nav-sticky-col">
                      <DatePicker
                        className="sticky-header-search-city-date"
                        placeholderText="Please select a date"
                        selected={startDate}
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                      />
                    </div>
                    <div className="col-lg-2 nav-sticky-col">
                      <Form className="select-form-seerch header-search-city-select">
                        <Form.Select
                          className="stick-header-form-select"
                          value={selectedOption}
                          onChange={handleSelectChange}
                        >
                          <option>--- select type ---</option>
                          <option value="boys"> Boys </option>
                          <option value="girls"> Girls </option>
                        </Form.Select>
                      </Form>
                    </div>
                    <div className="col-lg-2 nav-sticky-col">
                      <button
                        class="sticky-header-search-btn"
                        role="button"
                        onClick={searchHome}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* mobile view search bar start */}

            <div className="search-bar-mobile">
              <div className="mobile-search-bar-box">
                <input
                  type="text"
                  value={query}
                  onChange={handleInputChange}
                  placeholder="Type to search..."
                  className=""
                />
                <button
                  // onClick={handleSearchButtonClick}
                  className="mobile-view-search-btn"
                >
                  <FaSearch />
                </button>
              </div>
              {/* Display suggestions */}
              {/* <ul className="mobile-menu-suggestion-box">
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion}
                  </li>
                ))}
              </ul> */}

              {/* Display search result */}
              {searchResult && <p>{searchResult}</p>}
            </div>

            {/* mobile view search bar end */}
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              className="home-header-nave-sticky"
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Offcanvas
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="#action1">Home</Nav.Link>
                  <Nav.Link href="#action2">Link</Nav.Link>
                  <NavDropdown
                    title="Dropdown"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                      Something else here
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                  />
                  <Button variant="outline-success" className="">
                    Search
                  </Button>
                </Form>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
      <ToastContainer />
    </>
  );
};

export default StickyHeaders2;
