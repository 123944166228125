import React, { useState } from "react";
import "./Banner.css";

// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import { endpoints } from "../../Services/endpoints";
import { useEffect } from "react";
import axios from "axios";
import Skeleton from '@mui/material/Skeleton';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const Banner = () => {
  // slider api Implement
  const [bannerList, setBannerList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const slideUrl = endpoints.home.slider;

    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      "Accept": "application/json"
    };
    axios
      .get(slideUrl, { headers: headers })
      .then((res) => {
        if ( res.data.status === 1) {
          var slideData = res.data.data;
         
          // setBannerList(slideData);
          setBannerList([...slideData])
          setLoading(false);
          // console.log(slideData, "alll slide data");
        }
      })
      .catch((err) => {
        console.log(err, "Slider api not response");
        setLoading(false);
      });
  }, []);

  // Custom arrow components
  const Arrow = ({ className, style, onClick, direction }) => (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
      aria-label={direction}
      role="button" 
      tabIndex="0"
    />
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Arrow direction="next" />,
    prevArrow: <Arrow direction="prev" />,
  };
  

  return (
    <>
<div className="container-fluid p-0 homepage-slide-main-banner">
  <div className="row _taglist">
<div className="col-12 col-md-12 col-lg-6 p-0">
  <div className="home-page-party-store-banner move-above-on-hover">
    {loading? (
       <Skeleton variant="rectangular" width="100%" height={180} />
    ):(
      <Slider {...settings}>
      {bannerList?.map((item, index) => {
         const key = `${item.link}-${index}`;
        return (
          
         <Link to={`${item?.link}`} key={key} target="_blank">
            <div className="img-cover-no-stretch-slider image-main-banner">
              <div className="slider-img-blank-div">
                <img src={item?.slider_image} className="slider-img-main-page"alt=""/>
              </div>
            </div>
            </Link>
          
        );
      })}

  
    </Slider>
    )}
 
  </div>
</div>
<div className="col-12 col-md-12 col-lg-6 p-0">
<div className="home-page-party-store-banner move-above-on-hover">
    <h6 className="list-property-banner-text">List Your Property</h6>
    <Link to="https://admin.nearmehostel.com/register" className="codepen-button"><span>Click Here</span></Link>

    </div>
</div>
  </div>
</div>



  
     
    </>
  );
};

export default Banner;
