import React, { useEffect, useState } from "react";
import "./HostelCity.css";
import Lucknow from "./CityImges/lucknow.jpg";
import Kanpur from "./CityImges/cnb.jpg";
import Delhi from "./CityImges/delhi.jpg";
import Prayagraj from "./CityImges/allahabad.jpg";
import Varanasi from "./CityImges/varanasi.jpg";
import Banglore from "./CityImges/banglore.jpg";
import BgImg from "./CityImges/backgroungmap.png";
import { Link, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import OwlCarousel from 'react-owl-carousel2';
// import 'react-owl-carousel2/lib/styles.css'; 
import axios from "axios";

const HostelCity = () => {
  const [lucknow, setLucknow] = useState(false);
  const [kanpur, setKanpur] = useState(false);
  const [delhi, setDelhi] = useState(false);
  const [prayagraj, setPrayagraj] = useState(false);
  const [varanasi, setVaranasi] = useState(false);
  const [bangalore, setBangalore] = useState(false);
  const [allCities, setAllCities] = useState([]);
  const [isClient, setIsClient] = useState(false);
  const navigate = useNavigate();

  const cities = [
    { name: "Lucknow", img: Lucknow },
    { name: "Kanpur", img: Kanpur },
    { name: "Delhi", img: Delhi },
    { name: "Prayagraj", img: Prayagraj },
    { name: "Varanasi", img: Varanasi },
    { name: "Banglore", img: Banglore },
  ];

  const handleLucknow = () => {
    setLucknow(true);
  };
  const handleKanpur = () => {
    setKanpur(true);
  };
  const handleDelhi = () => {
    setDelhi(true);
  };
  const handlePrayagraj = () => {
    setPrayagraj(true);
  };
  const handleVaranasi = () => {
    setVaranasi(true);
  };
  const handleBangalore = () => {
    setBangalore(true);
  };

  useEffect(() => {
    setIsClient(true);

    const fetchCities = async () => {
      try {
        const headers = {
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json",
        };
        const response = await axios.get(
          "https://admin.nearmehostel.com/api/cities",
          { headers }
        );
        const data = response.data;
        if (data.status === 1) {
          setAllCities(data.data);
          console.log("Received cities data:", data.data);
        } else {
          console.error("City API returned status:", data.status);
        }
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };

    fetchCities();
  }, []);
  const options = {
    loop: true,
    margin: 10,
    items: 1,
    autoplay: true,
    responsive: {
      0: {
        items: 2,
        nav: true,
      },
      300: {
        items: 2.5,
        nav: false,
      },
      600: {
        items: 3.8,
        nav: false,
      },
      1000: {
        items: 5,
        nav: true,
        loop: false,
      },
    },
  };

  if (!isClient) {
    return null;
  }

  return (
    <>
      <div className="world-wide">
        <div className="row all-hide-city">
          <div className="col-lg-6">
            <div className="world-wide-left-area ">
              <div className="hostelExpanding__countryImage active  hostelimg-country-section1">
                <div className="city-img-box">
                  <img
                    src={Kanpur}
                    className="city-img1"
                    alt="landing background map"
                  />
                </div>
                {Kanpur ? (
                  <div className="city-name-txt-home1">Kanpur</div>
                ) : null}
              </div>
              <div className="hostelExpanding__countryImage active  hostelimg-country-section2">
                <div className="city-img-box">
                  <img
                    src={Delhi}
                    className="city-img1"
                    alt="landing background map"
                  />
                </div>
                {Delhi ? (
                  <div className="city-name-txt-home1">Delhi</div>
                ) : null}
              </div>
              <div className="hostelExpanding__countryImage active  hostelimg-country-section3">
                <div className="city-img-box">
                  <img
                    src={Prayagraj}
                    className="city-img1"
                    alt="landing background map"
                  />
                </div>
                {Prayagraj ? (
                  <div className="city-name-txt-home1">Prayagraj</div>
                ) : null}
              </div>
              <div className="hostelExpanding__countryImage active  hostelimg-country-section4">
                <div className="city-img-box">
                  <img
                    src={Varanasi}
                    className="city-img1"
                    alt="landing background map"
                  />
                </div>
                {Varanasi ? (
                  <div className="city-name-txt-home1">Varanasi</div>
                ) : null}
              </div>
              <div className="hostelExpanding__countryImage active  hostelimg-country-section5">
                <div className="city-img-box">
                  <img
                    src={Banglore}
                    className="city-img1"
                    alt="landing background map"
                  />
                </div>
                {Banglore ? (
                  <div className="city-name-txt-home1">Banglore</div>
                ) : null}
              </div>
              <div className="hostelExpanding__countryImage active  hostelimg-country-section6">
                <div className="city-img-box">
                  <img
                    src={Lucknow}
                    className="city-img1"
                    alt="landing background map"
                  />
                </div>
                {Lucknow ? (
                  <div className="city-name-txt-home1">Lucknow</div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="world-wide-right-section">
              <div className="world-wide-right-inner-box">
                <h3 className="d-heading hostelExpanding__heading">
                  World's leading chain of hostels and homes
                </h3>
                <p className="d-text16 u-extraLineHeight hostelExpanding__text">
                  More Destinations. More Ease. More Affordable.
                </p>
                <div className="hostelExpanding__insights">
                  <div className="hostelExpanding__insightItemWrap">
                    <div className="d-text14 hostelExpanding__insightItem">
                      <span className="is-fontBold">35</span>
                      <p className="is-font-city-bold">City</p>
                    </div>
                    <div className="slash"></div>
                  </div>
                  <div className="hostelExpanding__insightItemWrap">
                    <div className="d-text14 hostelExpanding__insightItem">
                      <span className="is-fontBold">157,000+</span>
                      <p className="is-font-city-bold">Hostels & PG</p>
                    </div>
                  </div>
                </div>
                <ul className="hostelExpanding__cityList">
                  {allCities.map((city) => (
                    <li className="d-text16 is-fontBold hostelExpanding__city ">
                      <span className="hostelExpanding__cityColor city1">
                        &nbsp;
                      </span>
                      <span className="hostelExpanding__cityLink">
                        <Link to={`/hostel/${city?.city}`}>{city?.city}</Link>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile view  start here*/}
        <div className="container-fluid mt-4 carousel-cont">
          <h3 className="slider-heading-text">Explore your next destination</h3>
          <div className="row">
            {allCities.length > 0 ? (
              <OwlCarousel className="owl-theme mt-2" {...options}>
                {allCities.map((city) => (
                  <div className="item city-name" key={city.id}>
                    <div className="item-city-img-box">
                      <img src={city?.image} alt={city.city} />
                    </div>
                    <h4>
                      <Link to={`/hostel/${city?.city}`}>{city?.city}</Link>
                    </h4>
                  </div>
                ))}
              </OwlCarousel>
            ) : (
              <p>No cities to display</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HostelCity;
