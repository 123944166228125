import React from "react";
import "./LocationByHostel.css";
import { AiOutlineStar, AiOutlinePlus } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import Cvraman from "./../../../src/assets/hostelimg/cvramn.png";
import Bed from "./../../../src/assets/images/singlebed.png";
import { Link, useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NearHostelCard = ({ img, feature, data, rating }) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  // console.log("NearHostelCard data:", data);

  const newFeatures = feature.slice(0, 5);
  const newImg = img.slice(0, 1);

  const handleViewDetails = () => {
    if (token) {
      navigate(`/hotsel-detail/${data?.id}`);
    } else {
      toast("Please Login ", { type: "warning" });
      navigate("/login-signup");
    }
  };

  return (
    <div className="container-cards second-row-card">
      <div className="hostel-cards-col">
        {newImg.length !== 0 ? (
          <div className="media-img">
            {data?.hostel_membership && data.hostel_membership !== 0 && (
              <div className="ribbon">
                <span className="ribbon3">{data?.hostel_membership}</span>
              </div>
            )}
            {newImg.map((itm, idx) =>
              itm ? (
                <img key={idx} src={itm} alt="Hostel" />
              ) : (
                <Skeleton key={idx} height={250} variant="rectangular" />
              )
            )}
          </div>
        ) : (
          <Skeleton variant="rectangular" width={210} height={200} />
        )}
        <div className="hostel-details">
          <h3>{data?.hostel_name}</h3>
          <h6>
            <span className="home-lockation-txt">
              {data?.hostel_address}, {data?.city}
            </span>
            {rating.map((itm, idx) => (
              <span key={idx} className="home-rating">
                <span className="rating-point">{itm.average_rating}</span>
                <span className="home-rating-star">
                  <AiOutlineStar />
                </span>
              </span>
            ))}
          </h6>
        </div>
        <div className="hostel-facility-details">
          <ul className="facility-text">
            {newFeatures.map((item, index) => (
              <li key={index}>
                <span>
                  <TiTick />
                </span>
                {item}
              </li>
            ))}
            <li>
              <Link onClick={handleViewDetails}>
                View More
                <span className="btn-pluse">
                  <AiOutlinePlus />
                </span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="hostel-bed-avility-details">
          <h6>
            <span className="bed-avl-txt">Rent From</span>
            <span className="home-card-rating">
              <button className="book-now-home-btn" onClick={handleViewDetails}>
                Book Now
              </button>
            </span>
          </h6>
          <ul>
            {data?.single_bed_rent && (
              <li>
                <img src={Bed} alt="Single Bed" />
                &nbsp;
                <span className="hostel-bed-price">
                  Rs.{data?.single_bed_rent}/-
                </span>
              </li>
            )}
            {data?.double_bed_rent && (
              <li>
                <img src={Bed} alt="Double Bed" />
                <img src={Bed} alt="Double Bed" />
                &nbsp;
                <span className="hostel-bed-price">
                  Rs.{data?.double_bed_rent}/-
                </span>
              </li>
            )}
            {data?.triple_bed_rent && (
              <li>
                <img src={Bed} alt="Triple Bed" />
                <img src={Bed} alt="Triple Bed" />
                <img src={Bed} alt="Triple Bed" />
                &nbsp;
                <span className="hostel-bed-price">
                  Rs.{data?.triple_bed_rent}/-
                </span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

const LocationByHostel2 = ({ nearHosteldata }) => {
  console.log("LocationByHostel2 nearHosteldata:", nearHosteldata);

  const hostelsArray = Array.isArray(nearHosteldata)
    ? nearHosteldata
    : nearHosteldata?.hostels || [];

  if (hostelsArray.length === 0) {
    console.log("No hostel data available or data is not an array");
    return <div>No hostels available</div>;
  }

  return (
    <div className="container-fluid p-0 popular-cont">
      <div className="_category-page-all-hostels-section material-shadow-card-light">
        <div className="_block-header">
          <div className="category-page-list-heading">Hostels / PG</div>
        </div>
        <div className="row">
          {hostelsArray.map((hostel, index) => {
            // console.log("Mapping hostel:", hostel);
            return (
              <div className="col-lg-3 col-md-6 col-sm-6" key={index}>
                <div className="item city-name">
                  <NearHostelCard
                    data={hostel}
                    img={hostel.hostel_images}
                    feature={hostel.hostel_features}
                    rating={hostel.reviews}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default LocationByHostel2;
