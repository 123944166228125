import React, { useState } from "react";
import "./LocationDetail.css";
import Down from "./../../assets/images/downarrow.png";
import { Link, useNavigate } from "react-router-dom";
const LocationDetail = (props) => {
  // const { getUnderLocation } = props;
 const { data } = props;
  // const [div, setdiv] = useState(false);
  const navigate=useNavigate();

  // const handleEvent = (id) => {
  //   setdiv(true);
  //   getUnderLocation(id);
  // };

  const handleViewHostel = (e) => {
    navigate(`/hostel/${data.city}`);
  };

  return (
    <>
      <div>
        <div
          className="down"
          // onMouseEnter={() => handleEvent(props.cityId)}
          // onMouseLeave={() => {
          //   setdiv(false);
          // }}
        >
          <p onClick={handleViewHostel}>{props.name}</p>
          <img src={Down} alt="down" />
        </div>
      </div>
      {/* {div ? (
        <div
          className="dropdown-sub-menu"
          onMouseEnter={handleEvent}
          onMouseLeave={() => {
            setdiv(false);
          }}
        >
          {props.more?.map((e, index) => (
            <p key={index} onClick={()=>handleViewHostel(e)}>
              {e?.location}
            </p>
          ))}
        </div>
      ) : null} */}
    </>
  );
};

export default LocationDetail;
