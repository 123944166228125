import React from "react";
import "./OurMission.css";
import Mission from "./OurMissionImg/mission.png";
import parse from "html-react-parser";

const OurMission = (props) => {
  const { allAboutData } = props;
  const allAboutContent=allAboutData[0]?.mission_content || " ";
  const missionImage = allAboutData[0]?.mission_image || " ";
  return (
    <>
      <section className="our-mission-main">
        <div className="container our-mission-cont">
          <div className="row center">
            <div className="column-6">
              {/* <h2 className="our-mission-heading-text">Our Mission </h2> */}
              <p className="our-mission-discrtption">
                {parse(allAboutContent)}
              </p>
            </div>
            <div className="column-6">
              <div className="our-mission-banner-img">
                <img src={missionImage} width="645" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurMission;
