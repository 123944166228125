import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "./ImageCareousel.css";
import { Skeleton } from "@mui/material";

import { GrNext ,GrPrevious } from "react-icons/gr";

const ImageCareousel = (props) => {
  const { data, key } = props;
  const [loading, setLoading] = useState(true);
  const [img, setImg] = useState(data?.hostel_images[0]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const imgData = data.hostel_images.slice(0, 5);

  useEffect(() => {
    if (imgData?.length > 0) {
      setLoading(false); // Assuming images are "loaded" for simplicity
    }
  }, [imgData]);


  const handleImg = (e) => {
    setImg(e.target.src);
  };

  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % imgData?.length;
    setCurrentIndex(nextIndex);
    setImg(imgData[nextIndex]);
  };

  const handlePrev = () => {
    const prevIndex = (currentIndex - 1 + imgData?.length) % imgData?.length;
    setCurrentIndex(prevIndex);
    setImg(imgData[prevIndex]);
  };

  return (
    <>
      {data && (
        <div className="image-caraousel">
          <div className="left-img-slider">
            <img src={img} alt="" />
            <button onClick={handlePrev} className="img-carousel-prev btn">
              {" "}
              <GrPrevious  />{" "}
            </button>
            <button onClick={handleNext} className="img-carousel-next btn">
              <GrNext  />
            </button>
          </div>
          <div className="right-img-slider">
          {loading ? (
              <Skeleton variant="rectangular" height={300} />
            ) : (
              imgData?.map((image, idx) => (
                <img src={image} onClick={handleImg} key={idx} alt="" />
              ))
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ImageCareousel;
