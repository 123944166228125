import React from 'react'
import Checkbox from "@mui/material/Checkbox";
import styled from "styled-components";
import './Collection.css'
const Div = styled.div`
  display: flex;
  align-items:center ;

  & > p {
    margin-top: 4%;
   
  }
  & span {
    color: darkgray;
  }
`;

const label = { inputProps: { "aria-label": "Checkbox demo" } };


const Collection = () => {
  return (
    <>
        <p className='colletion-heding-txt'>Collections</p>
      <Div>
        <div>
          <Checkbox {...label} />
        </div>
        <p>
          <span className='collection-span-txt'>Sanitized before your eyes</span>
        </p>
      </Div>
      <Div>
        <div>
          <Checkbox {...label} />
        </div>
        <p>
          <span>Vaccinated Staff</span>
        </p>
      </Div>
      <Div>
        <div>
          <Checkbox {...label} />
        </div>
        <p>
          <span>Local IDs Accepted</span>
        </p>
      </Div>
      <Div>
        <div>
          <Checkbox {...label} />
        </div>
        <p>
          <span>Hotes Welcomes Guest</span>
        </p>
      </Div>
    </>
  )
}

export default Collection;