import React from "react";
import "./MyBooking.css";
// import StickyHeader from "./../../../src/Common/StickyHeader/StickyHeader";
import MyBookingCard from "../../Component/MyBookingInfo/MyBookingCard/MyBookingCard";
import Footer from "./../../Common/Footer/Footer";
import HostelMoneyCard from "../../Component/MyBookingInfo/HostelMoneyCard/HostelMoneyCard";
import BookingHistory from "../../Component/BookingInfo/BookingHistory/BookingHistory";
import StickyHeaders2 from "../../Common/StickyHeader/StickyHeaders2";
import { useEffect } from "react";
const MyBooking = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

  }, []); 

  
  return (
    <>
      <div className="My-booking-screen">
        <div className="hostel-screens-top">
          <StickyHeaders2 />
        </div>
        <div className="main-div-my-booking">
          <div className="my-bokking-layout c-pdvaup">
            <div className="mybooking-heading c-1age63q">
              <span className="u-textCapitalize c-bazc6g">hi ,Users</span>
            </div>
            <div className="mybooking-hostel-card c-1age63q">
            <MyBookingCard />
            <HostelMoneyCard/>
            </div>
            <div className="mybooking-hostel-card c-1age63q">
            <BookingHistory/>

            </div>
           
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default MyBooking;
