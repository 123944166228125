import React, { useState, useEffect } from "react";
import StarRatingComponent from "react-star-rating-component";
import "./StarRating.css";
import { endpoints } from "../../../Services/endpoints";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import RiviewHostelDetails from "./RiviewHostelDetails";

const SatrRating = ({ hostelId, hostelOwnerId, hostelReview, setHostelReview }) => {
  const [rating, setRating] = useState(1);
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [reviews, setReviews] = useState(hostelReview || []); // Initialize with the reviews from props
  const token = localStorage.getItem("token");
console.log(token,"token");

  const handleStarClick = (newRating) => {
    setRating(newRating);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  useEffect(() => {
    if (hostelReview) {
      setReviews(hostelReview);
    }
  }, [hostelReview]);

  const submitReview = (event) => {
    event.preventDefault();
    const submitUrl = endpoints.home.review;

    if (!description.trim()) {
      setErrors({ description: "Please write description" });
    } else {
      setErrors({});
      const newReview = {
        hostel_id: hostelId,
        user_id: hostelOwnerId,
        star: rating,
        description: description,
        name: hostelReview.name, // Using name from hostelReview props
        image: hostelReview.image, // Using image from hostelReview props
      };
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json, text/plain, */*",
        Authorization: `Bearer ${token}`,
      };

      axios
        .post(submitUrl, newReview, { headers: headers })
        .then((res) => {
          if (res.data.status === 1) {
            toast.success(res.data.message);
            setRating(1);
            setDescription("");
            setReviews((prevReviews) => [...prevReviews, newReview]);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.error("Review API not responding", err);
          toast.error("Failed to submit review. Please try again later.");
        });
    }
  };

  return (
    <>
      <div className="rating-box-detais">
        <h4>Write Reviews</h4>
        <h5>Select Star</h5>
        <div className="Reviews_reviews__content__left__star__Tpib0">
          <StarRatingComponent
            name="starRating"
            starCount={5}
            value={rating}
            onStarClick={handleStarClick}
          />
        </div>
        <p>Write Description:</p>
        <div className="discript-textarea">
          <textarea
            value={description}
            onChange={handleDescriptionChange}
            placeholder="Write your description here."
          />
        </div>
        <div className="error-msg">{errors.description}</div>
        <button
          data-label="Submit"
          className="rainbows-hovers"
          onClick={submitReview}
        >
          <span className="span-d">Submit Review</span>
        </button>
        <ToastContainer />
      </div>
      {/* Render RiviewHostelDetails component */}
      <RiviewHostelDetails reviews={reviews} />
    </>
  );
};

export default SatrRating;
