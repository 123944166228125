import React from "react";
import { styled } from "@mui/system";

import { Div } from "./Div4TopFilter";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";
import { SwitchUnstyled, switchUnstyledClasses } from "@material-ui/core";
import "./TopFilterBar.css";
import { common } from "@mui/material/colors";




const TopFilterBar = (props) => {
  const{msg}=props;
  const [age, setAge] = useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const label = { componentsProps: { input: { "aria-label": "Demo switch" } } };
  return (
    <>
      <div className="top-fiter-bar-main-box">
        <div className="searchCount">
          <span>{ msg}</span>
        </div>
        <div className="toolipbar-select-box">
        
          <span className="top-fil-bar-srt-txt">Sort By </span>
          <FormControl sx={{ m: 1, minWidth: 100 }}>
            <Select
              value={age}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              style={{
                width: "200px",
                height: "40px",
              }}
            >
              <MenuItem value="Popularity">Popularity</MenuItem>
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="common">Common</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    </>
  );
};

export default TopFilterBar;
