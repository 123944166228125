import React, { useEffect, useState } from "react";
import "./EnqModalForm.css";
import Logo from "./../../../../src/assets/images/NearMeHostel4.svg";
import { CgCloseO } from "react-icons/cg";
import { endpoints } from "../../../Services/endpoints";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
const EnqModalForm = (props) => {
  const { showModal, setShowModal, hostelOwnerId, hostelId } = props;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDiscription] = useState("");
  const [errors, setErrors] = useState({});
  const token = localStorage.getItem("token");
  const [bedTypes, setBedTypes] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userdata")) || {};

  console.log(userData,"user dta token enq");
  // Function to close modal
  const closeModal = () => {
    setShowModal(false);
    document.body.classList.remove("modal-open");
  };

  useEffect(() => {
    if (userData) {
      setName(userData.name || "");
      setEmail(userData.email || "");
      setPhone(userData.phone || "");
    }
  }, [userData]);

  useEffect(() => {
    // Add or remove 'modal-open' class based on showModal state
    if (showModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [showModal]);

  const enquiry = () => {
    const enquiryUrl = endpoints.home.enquiry;
    
    let validationErrors = {};

    if (description === "") {
      validationErrors.description = "Please write a description";
      toast.error(validationErrors.description); 
    }
    if (bedTypes.length === 0) {
      validationErrors.bedTypes = "Please select at least one bed type";
      toast.error(validationErrors.bedTypes);
    }
  
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    }
    else {
      setErrors({});
      // const roomTypeString = bedTypes.join(", ");
      const data = {
        name,
        email,
        phone,
        hostel_id: hostelId,
        owner_id: hostelOwnerId,
        description:description,
        room_type: JSON.stringify(bedTypes),
      };
      const headers = {
        "Content-Type": "application/json",
        Accept:"application/json, text/plain, */*",
        Authorization: `Bearer ${token}`,
      };

      axios
        .post(enquiryUrl, data, { headers: headers })
        .then((res) => {
          // console.log(res,"all enquiry data")
          if (res.data.status === 1) {
            toast(res.data.message, { type: "success" });
            setShowModal(false);
            document.body.classList.remove("modal-open");

            console.log(res.data.message, "Submitted succesfully");
          } else if (res.data.result === 0) {
            toast(res.data.message, { type: "error" });
          }
        })
        .catch((err) => {
          console.log(err, "this is the error here enquiry");
        });
    }
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setBedTypes((prevBedTypes) => {
      if (checked) {
        return [...prevBedTypes, name];
      } else {
        return prevBedTypes.filter((bedType) => bedType !== name);
      }
    });
  };


console.log("hjksafjlsf",bedTypes)

  return (
    <div>
      {showModal && (
        <div className="modal enq-modal">
          <div className="modal-content enq-modal-content">
            <div className="enq-form-header">
              <h6>Enquiry </h6>
              <span className="close close-btn" onClick={closeModal}>
                <CgCloseO />
              </span>
            </div>
            <div className="enq-logo">
              <img src={Logo} alt="enq logo" />
            </div>
            <div className="enu-form-box">
              <div className="form enq-form">
                <label>
                  <input
                    required
                    placeholder
                    type="text"
                    className="input enq-imput"
                    value={userData.name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <span>Enter name</span>
                </label>
               
                <label>
                  <input
                    required
                    placeholder
                    type="email"
                    className="input enq-imput"
                    value={userData.email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <span>email</span>
                </label>
               
                <label>
                  <input
                    required
                    type="tel"
                    placeholder
                    className="input enq-imput"
                    value={userData.phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <span>contact number</span>
                </label>
             
                <label>
                  <textarea
                    required
                    rows={3}
                    placeholder
                    className="input01"
                    defaultValue={""}
                    value={description}
                  onChange={(e) => setDiscription(e.target.value)}
                  />
                  <span>message</span>
                </label>
                <div className="bg-darak"></div>

                <label>
                  <input
                    type="checkbox"
                    name="singleBed"
                    checked={bedTypes.includes("singleBed")}
                    onChange={handleCheckboxChange}
                  />
                  &nbsp;
                  <span>Single Bed</span>
                  &nbsp; &nbsp;
                  <input
                    type="checkbox"
                    name="doubleBed"
                    checked={bedTypes.includes("doubleBed")}
                    onChange={handleCheckboxChange}
                  />
                  &nbsp;
                  <span>Double Bed</span>
                  &nbsp; &nbsp;
                  <input
                    type="checkbox"
                    name="tripleBed"
                    checked={bedTypes.includes("tripleBed")}
                    onChange={handleCheckboxChange}
                  />
                  &nbsp;
                  <span>Triple Bed</span>
                </label>
                {/* <div className="error-msg">{errors.bedTypes}</div> */}
                <button
                  data-label="Submit"
                  class="rainbow-hover"
                  onClick={enquiry}
                >
                  <span class="spn">Submit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default EnqModalForm;
