import React from "react";
import "./AboutUsScreen.css";
import BookingNavbar from "../Component/BookingInfo/BookingNavbar/BookingNavbar";
import Footer from "../Common/Footer/Footer";
import { Link } from "react-router-dom";
import Logo from "./../assets/images/logo.svg";
import OurMission from "../Component/AboutDetails/OurMission/OurMission";
import AboutUs from "../Component/AboutDetails/AboutUs/AboutUs";
import MessageCeo from "../Component/AboutDetails/MessageCeo/MessageCeo";
import WhatWeDo from "../Component/AboutDetails/WhatWeDo/WhatWeDo";
import { useEffect } from "react";
import { endpoints } from "../Services/endpoints";
import axios from "axios";
import { useState } from "react";
const AboutUsScreen = (props) => {
  const [allAboutData, setAllAboutData] = useState([]);


  const AboutData = () => {
    const aboutUrl = endpoints.home.aboutus;

    const headers = {
      "Content-Type": "application/json; charset=utf-8",
    };

    axios
      .get(aboutUrl, { headers: headers })

      .then((res) => {
        if (res.data.status == 1) {
          const aboutVal = res.data.data;
          setAllAboutData(aboutVal);
         
        }
      })
      .catch((err) => {
        console.log(err, "about api not response");
      });
  };
  useEffect(() => {
    AboutData();
  }, []);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };
    scrollToTop();
   
  }, []); 

  return (
    <>
      <div class="top-header">
        <nav class="navbar sticky-top navbar-expand-lg navbar-light bg-light bg-gradient">
          <div class="container">
            <div className="about-logo">
              <Link to="/">
                {" "}
                <img src={Logo} />
              </Link>
            </div>
          </div>
        </nav>

        <div class="main-ourmission">
          <OurMission allAboutData={allAboutData} />
          <AboutUs allAboutData={allAboutData} />
          <MessageCeo allAboutData={allAboutData} />
          <WhatWeDo allAboutData={allAboutData}/>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default AboutUsScreen;
