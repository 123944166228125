
import React, { useState } from "react";
import "./HostelInfo.css";
import "./HostelInfoMeadiaQuery.css";
import ImageCareousel from "./ImageCareousel";

import { Link } from "@mui/material";
import { MdLocationOn } from "react-icons/md";
import { BsPlus } from "react-icons/bs";
import { SiWebflow } from "react-icons/si";
import { AiFillFire, AiOutlineWifi } from "react-icons/ai";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import W from "./../../assets/images/w3.png";
import Bed from "./../../assets/images/singlebed.png";
import { useNavigate } from "react-router-dom/dist";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Skeleton } from "@mui/material";
const SuggestionHostelInfo = (props) => {
    const { data, key, rating } = props;
const token = localStorage.getItem("token");
   const feartureData = data.hostel_features.slice(0, 10);
   const navigate = useNavigate();
// console.log(data,"suggestion info data");
 const handleBookNow = () => {
    if (token) {
      navigate(`/hotsel-detail/${data?.id}`);
    } else {
      toast("Please Login ", { type: "warning" });
      navigate("/login-signup");
    }
  };

  return (
    <>
  <div className="hostelinfo-section">
        <div className="left-carousel">
          <ImageCareousel data={data} />
        </div>
        <div className="right-careousel-info">
          <div className="hostel-info-block">
            <div className="hostel-info-items-head">
              <div className="listingHotelDescription__contentWrapper">
                <div className="listingHotelDescription__contentWrapper--left">
                  <Link to="" className="c-nn640c u-width100">
                    <h3 className="listingHotelDescription__hotelName d-textEllipsis">
                      {data?.hostel_name}
                    </h3>
                  </Link>
                  <div
                    className="listingHotelDescription__hotelAddress"
                    itemprop="address"
                    itemscope="true"
                  >
                    <span itemprop="streetAddress" className="u-line--clamp-2">
                      {data?.hostel_address}
                    </span>
                    <span className="listingHotelDescription__dot">.</span>
                    <span className="listingHotelDescription__hotelAddressMap">
                      {/* <span>
                        <MdLocationOn />
                      </span>
                      <span className="listingHotelDescription__distanceText">
                        6.0 km
                      </span> */}
                    </span>
                  </div>
                </div>
                <div className="listingHotelDescription__contentWrapper--right">
                  <div className="c-1m75bgc">
                    <div className="c-1i6ioo7">
                      <AiFillFire />
                    </div>
                    <div className="c-1sugwtq">
                      9 people booked this hostel today
                    </div>
                  </div>
                </div>
              </div>
              <div className="hostelRating">
                <div
                  itemprop="aggregateRating"
                  itemscope="true"
                  className="hostelRating__wrapper"
                  tabindex="-1"
                >
                  {rating?.map((itm, idx) => (
                    <span
                      key={idx}
                      className="is-fontBold hostelRating__rating hostelRating__rating--good hostelRating__rating--clickable"
                    >
                      {itm && `${itm.average_rating} ★`}
                    </span>
                  ))}

                  <span className="hostelRating__ratingSummary  hostelRating__rating--clickable">
                    (300 Ratings)
                  </span>
                  <span className="hostelRating__dot">.</span>
                  <span className="hostelRating__ratingSummary">Good</span>
                </div>
              </div>

              <div className="amenityWrapper">
                {feartureData.map((item, index) => {
                  return (
                    <>
                      <div className="amenityWrapper__amenity" key={index}>
                        <span className="c-1in37i9 amenityWrapper__icon">
                          <IoIosCheckmarkCircleOutline />
                        </span>
                        <span className="d-body-sm d-textEllipsis" >
                          {item}
                        </span>
                      </div>
                    </>
                  );
                })}
                <div className="amenityWrapper__amenity txt-underline">
                  <span className="c-1in37i9 amenityWrapper__icon bsplus">
                    <BsPlus />
                  </span>
                  <span className="d-body-sm d-textEllipsis btn-view-all-hostel-info">
                    View All
                  </span>
                </div>
              </div>
              {data?.hostel_membership && (
                <div className="listingHostelDescription__labels">
                  <div className="c-hpocxd">
                    <div className="c-8sjad0">
                      <div className="c-ztdfbn">
                        <span className="w-icon-default ">
                          <img src={W} />
                        </span>
                      </div>
                    </div>
                    <div className="c-1vsluwb">{data?.hostel_membership}</div>
                  </div>
                </div>
              )}
              <div className="hostel-info-seat-price">
                <ul>
                  {data?.single_bed_rent && (
                    <li>
                      <img src={Bed} />{" "}
                      <span className="hostel-info-seat-price-text">
                        {data?.single_bed_rent}/-
                      </span>
                    </li>
                  )}
                  {data?.double_bed_rent && (
                    <li>
                      <img src={Bed} /> <img src={Bed} />{" "}
                      <span className="hostel-info-seat-price-text">
                        {data?.double_bed_rent}/-
                      </span>
                    </li>
                  )}
                  {data?.double_bed_rent && (
                    <li>
                      <img src={Bed} />
                      <img src={Bed} />
                      <img src={Bed} />{" "}
                      <span className="hostel-info-seat-price-text">
                        {data?.triple_bed_rent}/-
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="listingHostelDescription__priceBtn">
              <div className="hostel-row hostel-row--no-spacing">
                <div className="hostel-cell--7-col listingHostelDescription__btnWrapper">
                  <button
                    className="c-1k6asfw d-whiteButton"
                    type="button"
                    onClick={handleBookNow}
                  >
                    <sapn>View Details</sapn>
                  </button>
                  <button
                    className="c-1k6asfw d-greenButton"
                    onClick={handleBookNow}
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SuggestionHostelInfo;