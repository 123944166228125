import React, { useEffect, useState } from "react";
import "./SeatChartModal.css";
import Bed from "../../../assets/images/singlebed.png";
import { endpoints } from "../../../Services/endpoints";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SeatChartModal = ({
  isOpen,
  closeModal,
  selectedSeat,
  detailsData,
  hostelId,
  checkInDate,
  checkOutDate,
  bedFloor,
 
}) => {
  const [selectedBeds, setSelectedBeds] = useState([]);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [availableBeds, setAvailableBeds] = useState([]);
  const [ownerId, setOwnerId] = useState("");
  const navigate=useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchBedAvailability = async () => {
      const bedDataUrls = endpoints.home.bedAvailabilityData;
      const data = {
        hostel_id: hostelId,
        check_in_date: checkInDate,
        check_out_date: checkOutDate,
        floor: bedFloor,
        bed_type: selectedSeat,
      };

      console.log("Data sent to API:", data);
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json, text/plain, */*",
        Authorization: `Bearer ${token}`,
      };

      try {
        const response = await axios.post(bedDataUrls, data, { headers });
        if (response.data.status === 1) {
          setAvailableBeds(response.data.data);
          setOwnerId(response.data.owner_id)
          console.log(response.data.data, "all bed data seat chart floor");
          // document.body.classList.add("modal-open");
          if (!toast.isActive("success")) {
            toast.success(response.data.message);
          }
        } else {
          if (!toast.isActive("error")) {
            toast.error(response.data.message);
          }
          // document.body.classList.remove("modal-open");
        }
      } catch (error) {
        console.error("Bed Data availability API not responding", error);
        toast.error("Bed not available");
        // document.body.classList.remove("modal-open");
      }
    };

    fetchBedAvailability();
  }, [hostelId, checkInDate, checkOutDate, bedFloor, selectedSeat, token]);


  const toggleBedSelection = (bedId,roomId) => {
    if (selectedBeds.includes(bedId)) {
      setSelectedBeds(selectedBeds.filter((id) => id !== bedId));
      setSelectedRooms(selectedRooms.filter((id) => id !== roomId));
      
    } else {
      setSelectedBeds([...selectedBeds, bedId]);
      setSelectedRooms([...selectedRooms, roomId]);
    }
  };

  const calculateTotalPrice = () => {
    const basePrice = 200; 
    return basePrice * selectedBeds.length;
  };

  const handleBookNow = () => {
    const totalPrice = calculateTotalPrice();
    const bookingDetails = {
      hostelId,
      checkInDate,
      checkOutDate,
      bedFloor,
      selectedSeat,
      ownerId,
      selectedBeds,
      selectedRooms,
      detailsData,
      totalPrice,
      
    };
    console.log(selectedRooms,"selectedRooms");
    navigate("/booking-detail",{ state: bookingDetails })
    closeModal(); 
  };

  if (!isOpen) {
    return null;
  }
  return (
    <div className="seat-chart-modal">
      <div className="modal-content seat-chart-modal-content">
        <div className="seat-chart-modal-header">
          <h5 className="seat-chart-modal-header-text">Seat Chart</h5>
          
           
     
          <span className="close" onClick={closeModal}>
            &times;
          </span>
        </div>
        <div className="seat-chart-main-bed-image">
          <img src={Bed} />
        </div>
        <div className="selcted-bed-chart-modal"> 
        <p className=""> Selected Seat: {selectedSeat?.type}</p>
       <p> No of Seat : {selectedBeds.length} </p>
        </div>
        <div className="seat-chart-main-bed-box-main">
          <div className="seat-chart-main-bed-box">
            {availableBeds.map((bed, index) => (
              <div
              key={bed.bed_id}
                className={`seat-chart-main-bed-boxes ${
                  selectedBeds.includes(bed.bed_id) ? "selected" : ""
                }`}
                onClick={() => toggleBedSelection(bed.bed_id,bed.room_id)}
              >
                 Bed {index + 1}
              </div>
            ))}
          </div>
        </div>
        <div className="seat-chart-modal-footer">
          {/* <h5 className="seat-chart-modal-footer-text">
            No seat : &nbsp;{selectedBeds.length}
          </h5> */}
          <span className="seat-chart-modal-footer-text-price">
            Price : ₹ {calculateTotalPrice()}
          </span>
          <button type="button" className="booking-btn" onClick={handleBookNow}>
            Book Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default SeatChartModal;
