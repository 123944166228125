import React from "react";
import "./TermsCondition.css";
import { Link } from "react-router-dom";
import Logo from "./../../assets/images/logo.svg";
import Footer from "../../Common/Footer/Footer";
import { useEffect } from "react";
import { endpoints } from "../../Services/endpoints";
import axios from "axios";
import { useState } from "react";
import parse from "html-react-parser";

const TermsCondition = () => {
  const [termsConditions, setTermsConditions] = useState("");

  useEffect(() => {
    const termsConditionsUrls = endpoints.home.termsConditions;
    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
    };
    axios
      .get(termsConditionsUrls, { headers: headers })
      .then((res) => {
        if (res.data.status == 1) {
          var termsConditionsData = res.data.data[0]?.content;
          setTermsConditions(termsConditionsData);
          console.log(termsConditionsData, "termsConditions");
        }
      })
      .catch((err) => {
        console.log(err, "terms conditions api not response");
      });
  }, []);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    // window.addEventListener('scroll', scrollToTop);

    // return () => {
    //   window.removeEventListener('scroll', scrollToTop);
    // };
  }, []);
  return (
    <>
      <div class="top-header">
        <nav class="navbar sticky-top navbar-expand-lg navbar-light bg-light bg-gradient">
          <div class="container">
            <div className="terms-condition-logo">
              <Link to="/">
                {" "}
                <img src={Logo} />
              </Link>
            </div>
          </div>
        </nav>

        <div class="main-terms-condition">
          <div className="terms-condition-heading">
            <h2 className="terms-condition-heading-text">
              Terms And Condition
            </h2>
            <p className="terms-condition-sub-text">
              <span>
                <Link to="/">Home</Link>
              </span>
              &nbsp;<span>/</span>&nbsp;<span>Terms Condition</span>
            </p>
          </div>
          {termsConditions && (
            <div className="terms-condition-text-box">
              <div className="container">{parse(termsConditions)}</div>
            </div>
          )}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default TermsCondition;
